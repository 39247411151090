import styled from 'styled-components'

export const Wrapper = styled.div`
    position: relative;
`

export const FriendListContainer = styled.div`
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(120, 120, 120, 0.5);
    border-radius: 5px;
    width: 290px;
    height: 313px;

    padding: 20px;

    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 12px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #cccccc;
        border: 0.225em solid #f1f1f1;
        border-radius: 10px;

        :hover {
            background-color: #a6a6a6;
        }

        :active {
            background-color: #808080;
        }
    }

    h1 {
        position: absolute;
        left: 0;
        top: -29px;

        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;
        color: #000000;
    }
`

export const NoFriendsContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const Title = styled.h2`
    margin-top: 30px;

    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    color: #000000;
`

export const SubTitle = styled.h3`
    margin-top: 5px;

    font-family: 'Roboto';
    font-weight: 300;
    font-size: 13px;

    a {
        text-decoration: underline;
        color: #ff9100;
    }
`

// Friend

export const FriendsContainer = styled.div`
    margin-bottom: 20px;

    > div + div {
        margin-top: 10px;
    }
`

export const Friend = styled.div`
    margin-left: 10px;
    display: flex;
    align-items: center;

    opacity: ${props => (props.offline ? '50%' : '100%')};
`

export const AvatarWrapper = styled.div`
    position: relative;
    width: 35px;
    height: 35px;
`

export const FriendAvatar = styled.img`
    width: 35px;
    height: 35px;
    border-radius: 50%;
`

export const Status = styled.div`
    position: absolute;
    width: 10.5px;
    height: 10.5px;
    border-radius: 50%;
    right: 1.75px;
    bottom: 0;

    background: ${props => (props.status === 'online' ? '#00e676' : '#E31D1D')};
`

export const FriendName = styled.div`
    margin-left: 10px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 12px;
    color: #1a1a1a;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

// FriendRequest

export const FriendRequestContainer = styled.div`
    > div + div {
        margin-top: 10px;
    }
`

export const Topic = styled.h5`
    margin-bottom: 10px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: #000000;
`

export const Request = styled.div`
    width: 250px;
    height: 60px;

    display: flex;
    align-items: center;

    background: #f4fbfd;
    border-radius: 5px;
`

export const Avatar = styled.img`
    margin-left: 15px;
    width: 40px;
    height: 40px;
    border-radius: 5px;
`

export const Name = styled.div`
    flex: 1;
    margin-left: 12px;

    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 12px;
    color: #000000;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

export const Confirm = styled.button`
    margin-right: 5px;
    font-size: 0;
    min-width: 30px;
    min-height: 30px;
    background: #00e676;
    border-radius: 5px;

    cursor: pointer;

    transition: all 0.2s ease-out;

    :hover {
        filter: brightness(0.9);
    }
`

export const Delete = styled.button`
    margin-right: 10px;
    font-size: 0;
    min-width: 30px;
    min-height: 30px;
    background: #ff4444;
    border-radius: 5px;

    cursor: pointer;

    transition: all 0.2s ease-out;

    :hover {
        filter: brightness(0.9);
    }
`
