import { useState, useEffect } from 'react'
// import { useParams } from 'react-router-dom'

import SalaInfo from '../../components/SalaInfo'
import SalaChat from '../../components/SalaChat'
import SalaUsers from '../../components/SalaUsers'

import SalaSettings from '../../components/SalaSettings'
import SalaExit from '../../components/SalaExit'

import avatar from './../../assets/avatar/avatar.jpg'
import { SalaContainer, Expand, Wrapper } from './styles'

function Sala() {
    const [values, setValues] = useState({})
    const [permission, setPermission] = useState(0)
    const [users, setUsers] = useState([])
    const [messages, setMessages] = useState([])
    const [expand, setExpand] = useState(false)
    // const { id } = useParams()

    useEffect(() => {
        setValues({
            gameName: 'League of legends',
            roomImage:
                'https://cdn.vox-cdn.com/thumbor/HyOhm280EOQO2ubcOZCSONkDGb8=/0x0:1200x675/1200x800/filters:focal(504x242:696x434)/cdn.vox-cdn.com/uploads/chorus_image/image/68567666/Dr._STONE_Season_2_release_date_Episode_24_ending_with_Stone_Wars_Dr._STONE_manga_compared_to_the_anime_Spoilers.0.jpg',
            roomName: 'Domadores de Tahm kench',
            roomDescription:
                'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem.',
            createdBy: 'DarkGameslvl',
            hasCreated: '04/05/2021 - 17:58:40',
        })
        setPermission(1)
        setUsers([
            { avatar: avatar, name: 'DarkGameslvl', status: 'online' },
            { avatar: avatar, name: 'Pedro', status: 'ocupado' },
            { avatar: avatar, name: 'Gustavo', status: 'offline' },
        ])
        setMessages([
            {
                avatar: avatar,
                name: 'DarkGameslvl',
                time: '04:15',
                message: [
                    'Hoje eu to podendo jogar galera!',
                    'Se vocês forem jogar, avisa. ',
                ],
            },
            {
                avatar: avatar,
                name: 'DarkGameslvl',
                time: '04:50',
                message: [
                    'Então mano, hoje não vai dar pra mim não. Talvez a noite eu possa, ainda tenho que ver direito. ',
                    'Se vocês forem jogar, avisa. ',
                    'Hoje eu to livre galera, vou jogar 100%',
                ],
            },
        ])
    }, [])

    return (
        <SalaContainer>
            <Wrapper>
                <SalaInfo values={values} permission={permission} />
                <SalaChat messages={messages} />
                <SalaUsers users={users} display={expand} />
                <Expand
                    onClick={() => setExpand(!expand)}
                    className={expand ? 'active' : ''}
                >
                    <svg
                        width="8"
                        height="12"
                        viewBox="0 0 8 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M0.94412 1.46258L4.89299 5.86133L0.944119 10.2601L2.15982 11.6113L7.33301 5.86133L2.15982 0.111328L0.94412 1.46258Z" />
                    </svg>
                </Expand>
            </Wrapper>
            <SalaSettings expand={expand} permission={permission} />
            <SalaExit expand={expand} permission={permission} />
        </SalaContainer>
    )
}

export default Sala
