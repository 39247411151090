import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import {
    Wrapper,
    FriendListContainer,
    Title,
    SubTitle,
    NoFriendsContainer,
} from './styles'

import {
    FriendsContainer,
    Friend,
    AvatarWrapper,
    FriendAvatar,
    Status,
    FriendName,
} from './styles'

import {
    FriendRequestContainer,
    Request,
    Topic,
    Avatar,
    Name,
    Confirm,
    Delete,
} from './styles'

import avatar from './../../assets/avatar/avatar.jpg'
import noFriends from './../../assets/svg/noFriends.svg'
import check from './../../assets/svg/check.svg'
import close from './../../assets/svg/close.svg'

function FriendList() {
    const [friends, setFriends] = useState([])
    const [request, setRequest] = useState([])

    useEffect(() => {
        setRequest([{ avatar: avatar, name: 'Samuel' }])
        setFriends([
            { avatar: avatar, name: 'DarkGameslvl', status: 'online' },
            { avatar: avatar, name: 'Pedro', status: 'ocupado' },
            { avatar: avatar, name: 'Gustavo', status: 'offline' },
        ])
    }, [])

    return (
        <Wrapper>
            <FriendListContainer>
                <h1>Sua lista de amigos</h1>
                {friends.length ? <Friends friends={friends} /> : ''}
                {request.length ? <FriendRequest request={request} /> : ''}
                {!friends.length && !request.length ? <NoFriends /> : ''}
            </FriendListContainer>
        </Wrapper>
    )
}

function Friends({ friends }) {
    const online = friends.filter(friend => friend.status !== 'offline')
    const offline = friends.filter(friend => friend.status === 'offline')

    return (
        <>
            {online.length ? (
                <FriendsContainer>
                    <Topic>Online</Topic>
                    {online.map((friend, index) => {
                        return (
                            <Friend key={index}>
                                <AvatarWrapper>
                                    <FriendAvatar
                                        src={friend.avatar}
                                        alt="Avatar"
                                    />
                                    <Status status={friend.status} />
                                </AvatarWrapper>
                                <FriendName>{friend.name}</FriendName>
                            </Friend>
                        )
                    })}
                </FriendsContainer>
            ) : (
                ''
            )}
            {offline.length ? (
                <FriendsContainer>
                    <Topic>Offline - {offline.length}</Topic>
                    {offline.map((friend, index) => {
                        return (
                            <Friend key={index} offline>
                                <AvatarWrapper>
                                    <FriendAvatar
                                        src={friend.avatar}
                                        alt="Avatar"
                                    />
                                </AvatarWrapper>
                                <FriendName>{friend.name}</FriendName>
                            </Friend>
                        )
                    })}
                </FriendsContainer>
            ) : (
                ''
            )}
        </>
    )
}

function FriendRequest({ request }) {
    return (
        <FriendRequestContainer>
            <Topic>Solicitações de amizade</Topic>
            {request.map((request, index) => {
                return (
                    <Request key={index}>
                        <Avatar src={request.avatar} alt="Avatar" />
                        <Name>{request.name}</Name>
                        <Confirm>
                            <img src={check} alt="Confirmar" />
                        </Confirm>
                        <Delete>
                            <img src={close} alt="Rejeitar" />
                        </Delete>
                    </Request>
                )
            })}
        </FriendRequestContainer>
    )
}

function NoFriends() {
    return (
        <NoFriendsContainer>
            <img src={noFriends} alt="Sem amigos" />
            <Title>Você não possui amigos...</Title>
            <SubTitle>
                <Link to="/buscar/jogadores">Clique aqui</Link> para conhecer
                novas pessoas.
            </SubTitle>
        </NoFriendsContainer>
    )
}

export default FriendList
