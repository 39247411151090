import { useState } from 'react'

import profile from './../../assets/svg/profile.svg'

import {
    UserProfileContainer,
    Information,
    InfoWrapper,
    InfoTitle,
    InfoSubTitle,
    InputWrapper,
    Text,
    Button,
} from './styles'

import { InputContainer } from './styles'

function UserProfile() {
    const [user, setUser] = useState('DarkGameslvl')
    const [email, setEmail] = useState('Testando@outlook.com')
    const [password, setPassword] = useState('***********')
    const [rePassword, setRePassword] = useState('***********')

    return (
        <UserProfileContainer>
            <Information>
                <img src={profile} alt="Card" />
                <InfoWrapper>
                    <InfoTitle>Informações Confidenciais </InfoTitle>
                    <InfoSubTitle>
                        Suas informações de cadastro podem ser editadas por você
                        a qualquer momento.
                    </InfoSubTitle>
                </InfoWrapper>
            </Information>
            <InputWrapper>
                <Input
                    label="Usuário"
                    type="text"
                    value={user}
                    handleChange={setUser}
                />
                <Input
                    label="Email"
                    type="email"
                    value={email}
                    handleChange={setEmail}
                />
                <Input
                    label="Senha"
                    type="password"
                    value={password}
                    handleChange={setPassword}
                />
                <Input
                    label="Confirmação de senha"
                    type="password"
                    value={rePassword}
                    handleChange={setRePassword}
                />
            </InputWrapper>
            <Text>
                Nenhuma alteração será feita caso não clique em “salvar
                mudanças”.
            </Text>
            <Button>Salvar mudanças</Button>
        </UserProfileContainer>
    )
}

function Input({ className, label, type, value, handleChange }) {
    return (
        <InputContainer>
            <input
                id={label}
                className={className}
                type={type}
                value={value}
                onChange={e => handleChange(e.target.value)}
                placeholder=" "
            />
            <label htmlFor={label}>{label}</label>
        </InputContainer>
    )
}

export default UserProfile
