import { SalasNavActionsContainer, StyledLink } from './styles'

function SalasNavActions({ page }) {
    return (
        <SalasNavActionsContainer>
            <StyledLink blue="true" to="/buscar/sala/criar-sala/">
                <svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M11.001 6.28571H6.28669V11H4.71526V6.28571H0.000976562V4.71429H4.71526V0H6.28669V4.71429H11.001V6.28571Z"
                        fill="white"
                    />
                </svg>
                Criar sala
            </StyledLink>
            <StyledLink
                to={page === 1 ? '/buscar/sala/minhas-salas/' : '/buscar/sala'}
            >
                <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M13.9818 5.07121C13.9336 4.9227 13.8053 4.81448 13.6508 4.79205L9.31073 4.16137L7.36975 0.22862C7.30068 0.0886208 7.15808 0 7.00198 0C6.84584 0 6.70327 0.0886208 6.63417 0.22862L4.69311 4.16137L0.353124 4.79205C0.19866 4.81448 0.0702813 4.9227 0.0220471 5.07118C-0.0262144 5.21968 0.0140354 5.38268 0.125844 5.49164L3.26621 8.55287L2.52497 12.8754C2.49856 13.0293 2.56183 13.1848 2.68813 13.2766C2.75958 13.3285 2.84421 13.3549 2.92925 13.3549C2.99455 13.3549 3.06006 13.3393 3.12005 13.3078L7.00195 11.2669L10.8837 13.3078C11.0219 13.3804 11.1893 13.3683 11.3156 13.2766C11.4419 13.1848 11.5052 13.0293 11.4788 12.8754L10.7374 8.55287L13.8781 5.49161C13.9899 5.38268 14.0301 5.21968 13.9818 5.07121Z"
                        fill="white"
                    />
                </svg>
                Minhas salas
            </StyledLink>
        </SalasNavActionsContainer>
    )
}

export default SalasNavActions
