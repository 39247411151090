import { Switch, Route } from 'react-router-dom'

import UserAvatar from '../../components/UserAvatar'
import UserConfig from '../../components/UserConfig'
import UserNavActions from '../../components/UserNavActions'
import UserProfile from '../../components/UserProfile'

import { UserContainer, Wrapper, Content } from './styles'

function User() {
    return (
        <UserContainer>
            <Wrapper>
                <UserAvatar />
                <UserNavActions />
            </Wrapper>
            <Content>
                <Switch>
                    <Route exact path="/user/perfil" component={UserProfile} />
                    <Route exact path="/user/config" component={UserConfig} />
                </Switch>
            </Content>
        </UserContainer>
    )
}

export default User
