import styled from 'styled-components'

export const Wrapper = styled.div`
    position: relative;

    display: flex;
    justify-content: space-between;

    height: 650px;

    background: #f6f7fa;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
    border-radius: 5px;

    z-index: 5;
`

export const SalaContainer = styled.div`
    position: relative;
    margin: 50px 5%;

    z-index: 5;
`

export const Expand = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    width: 40px;
    height: 21px;

    position: absolute;
    top: 7px;
    right: 294px;

    background: #ffffff;
    border: 1px solid #e2e2e2;
    border-radius: 0 15px 15px 0;

    z-index: 3;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    &.active {
        right: -22px;

        :hover {
            right: -30px;
        }

        > svg {
            transform: rotate(-180deg);
        }
    }

    > svg {
        transition: all 0.25s ease-in-out;
        fill: #e2e2e2;
        margin-right: 6px;
    }

    :hover {
        border: 1px solid #6d6d6d;
        right: 286px;

        > svg {
            fill: #6d6d6d;
        }
    }
`
