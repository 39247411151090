import styled from 'styled-components'

export const UserProfileContainer = styled.div`
    width: fit-content;
`

export const Information = styled.div`
    display: flex;
    align-items: center;

    width: 545px;
    height: 139px;

    background: linear-gradient(180deg, #ecf5ff 0%, #fafcff 100%);
    border-radius: 5px;

    margin-bottom: 40px;

    img {
        margin-left: 30px;
        width: 96.08px;
        height: 67px;
    }
`

export const InfoWrapper = styled.div`
    margin-left: 30px;
    width: 309px;
`

export const InfoTitle = styled.div`
    margin-bottom: 10px;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 22px;
    color: #2e2e2e;
`

export const InfoSubTitle = styled.div`
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 12px;
    color: #2e2e2e;
`

export const InputWrapper = styled.div`
    width: 325px;
    height: 224px;
    margin-bottom: 35px;
`

export const Text = styled.div`
    width: 210px;
    margin-bottom: 20px;

    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    font-size: 10px;
    color: #363636;
`

export const Button = styled.button`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;

    width: 162px;
    height: 41px;

    background: #bababa;
    border-radius: 5px;

    &.allowed {
        cursor: pointer;
        background: #2979ff;

        :hover {
            filter: brightness(0.9);
        }
    }
`

// Input Styles

export const InputContainer = styled.div`
    position: relative;
    width: 325px;
    height: 41px;
    margin-bottom: 20px;

    input {
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 500;
        color: #575757;

        position: absolute;
        top: 0;
        left: 0;
        width: 325px;
        height: 41px;

        border: 1px solid #2979ff;
        border-radius: 3px;
        outline: none;
        padding: 12px 0 12px 20px;

        background: none;
        transition: 0.25s ease-in-out;

        &.allowed {
            font-weight: 700;
            color: #000000;
        }

        :focus {
            border: 2.5px solid #2979ff;
        }

        :focus + label {
            top: -6px;
            left: 15px;
            color: #2979ff;
            font-size: 10px;
            font-weight: 600;
        }

        :not(:placeholder-shown):not(:focus) + label {
            top: -6px;
            left: 15px;
            font-size: 10px;
            font-weight: 600;
            color: #2977ffbf;
        }
    }

    label {
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 500;
        color: #575757bf;

        position: absolute;
        left: 15px;
        top: 12px;
        padding: 0 5px;

        background: #fff;

        transition: 0.25s ease-in-out;
    }
`
