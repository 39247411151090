import {
    SalaInfoContainer,
    GameName,
    RoomImage,
    RoomName,
    RoomDescription,
    CreatedBy,
    HasCreated,
    NoImageContainer,
    NoImageButton,
    NoImageTitle,
    NoImageLink,
} from './styles'

function SalaInfo({ values, permission }) {
    const {
        gameName,
        roomImage,
        roomName,
        roomDescription,
        createdBy,
        hasCreated,
    } = values

    function NoImage() {
        if (permission === 1) {
            return (
                <NoImageContainer>
                    <NoImageButton>
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M20 11.4286H11.4286V20H8.57143V11.4286H0V8.57143H8.57143V0H11.4286V8.57143H20V11.4286Z"
                                fill="#AEAEAE"
                            />
                        </svg>
                    </NoImageButton>
                    <NoImageTitle>
                        Hmm... Parece que seu grupo ainda não tem uma imagem!
                    </NoImageTitle>
                    <NoImageLink>Clique para adicionar</NoImageLink>
                </NoImageContainer>
            )
        }

        return (
            <NoImageContainer>
                <NoImageTitle small>
                    Este grupo não possui nenhuma imagem :/
                </NoImageTitle>
            </NoImageContainer>
        )
    }

    return (
        <SalaInfoContainer>
            <GameName>{gameName}</GameName>
            <RoomImage>
                {roomImage !== '' ? (
                    <img src={roomImage} alt="Imagem da sala" />
                ) : (
                    <NoImage />
                )}
            </RoomImage>
            <RoomName>{roomName}</RoomName>
            <RoomDescription>{roomDescription}</RoomDescription>
            <CreatedBy>Criado por: {createdBy}</CreatedBy>
            <HasCreated>{hasCreated}</HasCreated>
        </SalaInfoContainer>
    )
}

export default SalaInfo
