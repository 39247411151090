import styled from 'styled-components'

export const UserConfigContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;

    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 18px;
    color: #000000;
`
