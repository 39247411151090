import styled from 'styled-components'

export const WarningToRegisterContainer = styled.div`
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(120, 120, 120, 0.5);
    border-radius: 5px;
    width: 290px;
    height: 313px;
`

export const Title = styled.div`
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 24px;
    color: #000000;

    padding: 30px 0 19px 25px;

    > span {
        font-weight: 700;
        font-size: 36px;

        > span {
            font-weight: 500;
        }
    }
`

export const Paragraph = styled.div`
    width: 213px;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;

    padding: 0px 0 20px 25px;
`

export const Registrar = styled.button`
    background: #2979ff;

    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: white;

    width: 130px;
    height: 40px;

    border-radius: 5px;
    margin: 0px 0 30px 25px;

    cursor: pointer;
    transition: opacity 0.2s;

    :hover {
        opacity: 0.8;
    }
`
