import { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import {
    CriarSalaContainer,
    Back,
    Title,
    Section,
    TitleSection,
    Input,
    Admin,
    Text,
    Select,
    Button,
    PrivacityOption,
    PrivacityTitle,
    PrivacityDescription,
    ImagemContainer,
    ImageTextWrapper,
    ImageTitle,
    ImageSubTitle,
    ImageUploaded,
    Mask,
    Options,
    Option,
    Search,
    Svg,
    SearchInput,
    Container,
    NextSvg,
    RoomSimulator,
    SimulatorGameName,
    SimulatorImageContainer,
    SimulatorRoomName,
    SimulatorRoomDescription,
    CreatedBy,
    Time,
} from './styles'

import voltar from './../../assets/svg/voltar.svg'
import camera from './../../assets/svg/camera.svg'
import noImage from './../../assets/svg/noImage.svg'

function CriarSala() {
    const searchRef = useRef(null)
    let history = useHistory()
    const [adminEditName, setAdminEditName] = useState(false)
    const [adminEditDescription, setAdminEditDescription] = useState(false)
    const [privacityType, setPrivacityType] = useState('')
    const [search, setSearch] = useState('')
    const [game, setGame] = useState('Selecione seu jogo')
    const [gameList, setGameList] = useState([])
    const [open, setOpen] = useState(false)
    const [values, setValues] = useState({
        name: '',
        description: '',
    })

    useEffect(() => {
        setGameList([
            'Teste1',
            'Teste2',
            'Teste3',
            'Teste4',
            'Teste5',
            'Teste6',
        ])
    }, [])

    function closeOptions(e) {
        if (searchRef.current.contains(e.target)) return

        setOpen(false)
        document.removeEventListener('click', closeOptions, true)
    }

    function handleSelectClick() {
        setOpen(!open)
        document.addEventListener('click', closeOptions, true)
    }

    return (
        <CriarSalaContainer>
            <Container>
                <Back src={voltar} alt="Voltar" onClick={history.goBack} />
                <Title>Nova sala</Title>
                <Section>
                    <TitleSection>Nome da Sala</TitleSection>
                    <Input
                        type="text"
                        value={values.name}
                        onChange={e =>
                            setValues({ ...values, name: e.target.value })
                        }
                        placeholder="ex. Melhor Sala de Todas"
                    />
                    <Admin onClick={() => setAdminEditName(!adminEditName)}>
                        <Text>Apenas admin podem editar o nome da sala</Text>
                        <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={adminEditName ? '' : 'hidden'}
                        >
                            <path
                                d="M13.3333 0H1.66667C0.741667 0 0 0.75 0 1.66667V13.3333C0 14.25 0.741667 15 1.66667 15H13.3333C14.2583 15 15 14.25 15 13.3333V1.66667C15 0.75 14.2583 0 13.3333 0ZM5.83333 11.6667L1.66667 7.5L2.84167 6.325L5.83333 9.30833L12.1583 2.98333L13.3333 4.16667L5.83333 11.6667Z"
                                fill="#2979FF"
                            />
                        </svg>
                        <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={!adminEditName ? '' : 'hidden'}
                        >
                            <path
                                d="M13.3333 1.66667V13.3333H1.66667V1.66667H13.3333ZM13.3333 0H1.66667C0.75 0 0 0.75 0 1.66667V13.3333C0 14.25 0.75 15 1.66667 15H13.3333C14.25 15 15 14.25 15 13.3333V1.66667C15 0.75 14.25 0 13.3333 0Z"
                                fill="#5B5B5B"
                            />
                        </svg>
                    </Admin>
                </Section>
                <Section>
                    <TitleSection>Descrição da sala</TitleSection>
                    <Input
                        description
                        type="text"
                        value={values.description}
                        onChange={e =>
                            setValues({
                                ...values,
                                description: e.target.value,
                            })
                        }
                        placeholder="ex. Criada para unir a comunidade do jogo ..."
                    />
                    <Admin
                        onClick={() =>
                            setAdminEditDescription(!adminEditDescription)
                        }
                    >
                        <Text>Apenas admin podem editar o nome da sala</Text>
                        <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={adminEditDescription ? '' : 'hidden'}
                        >
                            <path
                                d="M13.3333 0H1.66667C0.741667 0 0 0.75 0 1.66667V13.3333C0 14.25 0.741667 15 1.66667 15H13.3333C14.2583 15 15 14.25 15 13.3333V1.66667C15 0.75 14.2583 0 13.3333 0ZM5.83333 11.6667L1.66667 7.5L2.84167 6.325L5.83333 9.30833L12.1583 2.98333L13.3333 4.16667L5.83333 11.6667Z"
                                fill="#2979FF"
                            />
                        </svg>
                        <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={!adminEditDescription ? '' : 'hidden'}
                        >
                            <path
                                d="M13.3333 1.66667V13.3333H1.66667V1.66667H13.3333ZM13.3333 0H1.66667C0.75 0 0 0.75 0 1.66667V13.3333C0 14.25 0.75 15 1.66667 15H13.3333C14.25 15 15 14.25 15 13.3333V1.66667C15 0.75 14.25 0 13.3333 0Z"
                                fill="#5B5B5B"
                            />
                        </svg>
                    </Admin>
                </Section>
                <Section>
                    <TitleSection>Escolha o jogo</TitleSection>
                    <Select>
                        <Mask
                            open={open}
                            onClick={handleSelectClick}
                            className={
                                game !== 'Selecione seu jogo' ? 'active' : ''
                            }
                        >
                            {game}
                        </Mask>
                        <Search ref={searchRef} open={open}>
                            <Svg
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={search !== '' ? 'ativado' : ''}
                            >
                                <path d="M10.7204 9.43396H10.0429L9.80274 9.2024C10.6432 8.2247 11.1492 6.9554 11.1492 5.57461C11.1492 2.49571 8.65352 0 5.57461 0C2.49571 0 0 2.49571 0 5.57461C0 8.65352 2.49571 11.1492 5.57461 11.1492C6.9554 11.1492 8.2247 10.6432 9.2024 9.80274L9.43396 10.0429V10.7204L13.7221 15L15 13.7221L10.7204 9.43396ZM5.57461 9.43396C3.43911 9.43396 1.71527 7.71012 1.71527 5.57461C1.71527 3.43911 3.43911 1.71527 5.57461 1.71527C7.71012 1.71527 9.43396 3.43911 9.43396 5.57461C9.43396 7.71012 7.71012 9.43396 5.57461 9.43396Z" />
                            </Svg>
                            <SearchInput
                                type="text"
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                                placeholder="Pesquisar jogo"
                                onFocus={() =>
                                    searchRef.current.classList.toggle('focus')
                                }
                                onBlur={() =>
                                    searchRef.current.classList.toggle('focus')
                                }
                            />
                        </Search>
                        <Options
                            open={open}
                            onClick={e => setGame(e.target.textContent)}
                        >
                            {gameList.map((option, index) => {
                                return (
                                    <Option
                                        key={index}
                                        className={
                                            option === game ? 'active' : ''
                                        }
                                    >
                                        {option}
                                    </Option>
                                )
                            })}
                        </Options>
                    </Select>
                </Section>
                <Section>
                    <TitleSection>Adicione uma imagem</TitleSection>
                    <ImagemContainer>
                        <ImageTextWrapper>
                            <ImageTitle>Envie uma imagem</ImageTitle>
                            <ImageSubTitle>
                                Você pode enviar uma imagem diretamente do seu
                                computador.
                            </ImageSubTitle>
                        </ImageTextWrapper>
                        <ImageUploaded>
                            <img src={camera} alt="Camera" />
                        </ImageUploaded>
                    </ImagemContainer>
                </Section>
                <Section>
                    <TitleSection>Selecione o tipo de privacidade</TitleSection>
                    <PrivacityOption
                        className={privacityType === 'public' ? 'active' : ''}
                        onClick={() => setPrivacityType('public')}
                    >
                        <PrivacityTitle>Público</PrivacityTitle>
                        <PrivacityDescription>
                            Qualquer um pode entrar na sala
                        </PrivacityDescription>
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={
                                privacityType === 'public' ? '' : 'hidden'
                            }
                        >
                            <path
                                d="M10.0003 5.83317C7.70033 5.83317 5.83366 7.69984 5.83366 9.99984C5.83366 12.2998 7.70033 14.1665 10.0003 14.1665C12.3003 14.1665 14.167 12.2998 14.167 9.99984C14.167 7.69984 12.3003 5.83317 10.0003 5.83317ZM10.0003 1.6665C5.40033 1.6665 1.66699 5.39984 1.66699 9.99984C1.66699 14.5998 5.40033 18.3332 10.0003 18.3332C14.6003 18.3332 18.3337 14.5998 18.3337 9.99984C18.3337 5.39984 14.6003 1.6665 10.0003 1.6665ZM10.0003 16.6665C6.31699 16.6665 3.33366 13.6832 3.33366 9.99984C3.33366 6.3165 6.31699 3.33317 10.0003 3.33317C13.6837 3.33317 16.667 6.3165 16.667 9.99984C16.667 13.6832 13.6837 16.6665 10.0003 16.6665Z"
                                fill="#2979FF"
                            />
                        </svg>
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={
                                privacityType !== 'public' ? '' : 'hidden'
                            }
                        >
                            <path
                                d="M10.0003 1.6665C5.40033 1.6665 1.66699 5.39984 1.66699 9.99984C1.66699 14.5998 5.40033 18.3332 10.0003 18.3332C14.6003 18.3332 18.3337 14.5998 18.3337 9.99984C18.3337 5.39984 14.6003 1.6665 10.0003 1.6665ZM10.0003 16.6665C6.31699 16.6665 3.33366 13.6832 3.33366 9.99984C3.33366 6.3165 6.31699 3.33317 10.0003 3.33317C13.6837 3.33317 16.667 6.3165 16.667 9.99984C16.667 13.6832 13.6837 16.6665 10.0003 16.6665Z"
                                fill="#DEDEDE"
                            />
                        </svg>
                    </PrivacityOption>
                    <PrivacityOption
                        className={privacityType === 'private' ? 'active' : ''}
                        onClick={() => setPrivacityType('private')}
                    >
                        <PrivacityTitle>Privado</PrivacityTitle>
                        <PrivacityDescription>
                            Apenas pessoas com acesso podem entrar
                        </PrivacityDescription>
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={
                                privacityType === 'private' ? '' : 'hidden'
                            }
                        >
                            <path
                                d="M10.0003 5.83317C7.70033 5.83317 5.83366 7.69984 5.83366 9.99984C5.83366 12.2998 7.70033 14.1665 10.0003 14.1665C12.3003 14.1665 14.167 12.2998 14.167 9.99984C14.167 7.69984 12.3003 5.83317 10.0003 5.83317ZM10.0003 1.6665C5.40033 1.6665 1.66699 5.39984 1.66699 9.99984C1.66699 14.5998 5.40033 18.3332 10.0003 18.3332C14.6003 18.3332 18.3337 14.5998 18.3337 9.99984C18.3337 5.39984 14.6003 1.6665 10.0003 1.6665ZM10.0003 16.6665C6.31699 16.6665 3.33366 13.6832 3.33366 9.99984C3.33366 6.3165 6.31699 3.33317 10.0003 3.33317C13.6837 3.33317 16.667 6.3165 16.667 9.99984C16.667 13.6832 13.6837 16.6665 10.0003 16.6665Z"
                                fill="#2979FF"
                            />
                        </svg>
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={
                                privacityType !== 'private' ? '' : 'hidden'
                            }
                        >
                            <path
                                d="M10.0003 1.6665C5.40033 1.6665 1.66699 5.39984 1.66699 9.99984C1.66699 14.5998 5.40033 18.3332 10.0003 18.3332C14.6003 18.3332 18.3337 14.5998 18.3337 9.99984C18.3337 5.39984 14.6003 1.6665 10.0003 1.6665ZM10.0003 16.6665C6.31699 16.6665 3.33366 13.6832 3.33366 9.99984C3.33366 6.3165 6.31699 3.33317 10.0003 3.33317C13.6837 3.33317 16.667 6.3165 16.667 9.99984C16.667 13.6832 13.6837 16.6665 10.0003 16.6665Z"
                                fill="#DEDEDE"
                            />
                        </svg>
                    </PrivacityOption>
                </Section>
                <Button>Criar sala</Button>
            </Container>

            <NextSvg
                width="70"
                height="52"
                viewBox="0 0 70 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M69.4014 24.4425L48.4023 1.11008C47.9543 0.615437 47.329 0.335449 46.6663 0.335449H32.6669C31.7476 0.335449 30.9123 0.87676 30.5343 1.71673C30.161 2.56136 30.315 3.54598 30.931 4.22729L50.5255 26.0011L30.931 47.7702C30.315 48.4561 30.1564 49.4408 30.5343 50.2807C30.9123 51.1254 31.7476 51.6667 32.6669 51.6667H46.6663C47.329 51.6667 47.9543 51.382 48.4023 50.8967L69.4014 27.5643C70.1994 26.6777 70.1994 25.3244 69.4014 24.4425Z" />
                <path d="M39.0704 24.4425L18.0712 1.11008C17.6232 0.615437 16.9979 0.335449 16.3353 0.335449H2.33586C1.41657 0.335449 0.581267 0.87676 0.203282 1.71673C-0.170036 2.56136 -0.0160421 3.54598 0.599933 4.22729L20.1945 26.0011L0.599933 47.7702C-0.0160421 48.4561 -0.174702 49.4408 0.203282 50.2807C0.581267 51.1254 1.41657 51.6667 2.33586 51.6667H16.3353C16.9979 51.6667 17.6232 51.382 18.0712 50.8967L39.0704 27.5643C39.8683 26.6777 39.8683 25.3244 39.0704 24.4425Z" />
            </NextSvg>

            <RoomSimulator>
                <SimulatorGameName>Nome do jogo</SimulatorGameName>
                <SimulatorImageContainer>
                    <img src={noImage} alt="Sem imagem" />
                </SimulatorImageContainer>
                <SimulatorRoomName>Nome da sala</SimulatorRoomName>
                <SimulatorRoomDescription>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem. It is a long
                    established fact that a reader will be distracted by the
                    readable content of a page when looking at its layout. The
                    point of using Lorem.
                </SimulatorRoomDescription>
                <CreatedBy>Criado por: DarkGameslvl</CreatedBy>
                <Time>04/05/2021 - 17:58:40</Time>
            </RoomSimulator>
        </CriarSalaContainer>
    )
}

export default CriarSala
