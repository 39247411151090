import { useState } from 'react'

import { BuscarJogadoresContainer } from './styles'

import JogadorSearch from '../../components/JogadorSearch'
import JogadorList from '../../components/JogadorList'

function BuscarJogadores() {
    const [search, setSearch] = useState('')

    return (
        <BuscarJogadoresContainer>
            <JogadorSearch search={search} setSearch={setSearch} />
            <JogadorList search={search} />
        </BuscarJogadoresContainer>
    )
}

export default BuscarJogadores
