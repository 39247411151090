import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import { AuthenticatedContextProvider } from './context/AuthenticatedContext'
import { RegisterAndLoginContextProvider } from './context/RegisterAndLoginContext'

import Navbar from './components/Navbar'
import MainRoutes from './routes'

import CreateGlobalStyle from './styles/GlobalStyles'

render(
    <Router>
        <AuthenticatedContextProvider>
            <RegisterAndLoginContextProvider>
                <Navbar />
                <MainRoutes />
                <CreateGlobalStyle />
            </RegisterAndLoginContextProvider>
        </AuthenticatedContextProvider>
    </Router>,
    document.getElementById('root')
)
