import { useState, createContext, useContext } from 'react'

import RegisterAndLogin from '../components/RegisterAndLogin'

const RegisterAndLoginContext = createContext({})

export const RegisterAndLoginContextProvider = ({ children }) => {
    const [page, setPage] = useState('Login')
    const [isVisible, setIsVisible] = useState(false)

    return (
        <RegisterAndLoginContext.Provider value={{ setIsVisible, setPage }}>
            {children}
            {isVisible && <RegisterAndLogin page={page} setPage={setPage} />}
        </RegisterAndLoginContext.Provider>
    )
}

export const useRegisterAndLogin = () => {
    return useContext(RegisterAndLoginContext)
}
