import styled from 'styled-components'

export const BuscarSalasContainer = styled.div`
    padding: 30px 12.5%;
`

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
`
