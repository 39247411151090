import styled from 'styled-components'

export const Svg = styled.svg`
    fill: #dddddd;
    margin: 0 10px 0 20px;

    &.ativado {
        fill: #000000;
    }
`

export const Search = styled.label`
    display: flex;
    align-items: center;

    z-index: 10;

    width: 350px;
    height: 40px;
    background: #ffffff;
    box-shadow: 5px 5px 25px -5px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    margin-right: 20px;

    &.focus {
        border: 1px solid #2979ff;

        svg {
            fill: #000000;
        }
    }
`

export const SearchInput = styled.input`
    width: 80%;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 13px;
    color: #000000;

    ::placeholder {
        color: #c4c4c4;
    }
`
