import { useAuthenticated } from '../../context/AuthenticatedContext'

import {
    UserNavActionsContainer,
    NavbarLinks,
    StyledNavLink,
    Disconnect,
} from './styles'

function UserNavActions() {
    const { setIsLogged } = useAuthenticated()

    function disconnect() {
        setIsLogged(false)
    }

    return (
        <UserNavActionsContainer>
            <NavbarLinks>
                <StyledNavLink to="/user/perfil" exact>
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M9.99967 10.0002C11.8413 10.0002 13.333 8.5085 13.333 6.66683C13.333 4.82516 11.8413 3.3335 9.99967 3.3335C8.15801 3.3335 6.66634 4.82516 6.66634 6.66683C6.66634 8.5085 8.15801 10.0002 9.99967 10.0002ZM9.99967 11.6668C7.77467 11.6668 3.33301 12.7835 3.33301 15.0002V16.6668H16.6663V15.0002C16.6663 12.7835 12.2247 11.6668 9.99967 11.6668Z" />
                    </svg>
                    Meu perfil
                </StyledNavLink>
                <StyledNavLink to="/user/config" exact>
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M15.9503 10.78C15.9803 10.53 16.0003 10.27 16.0003 10C16.0003 9.73 15.9803 9.47 15.9403 9.22L17.6303 7.9C17.7803 7.78 17.8203 7.56 17.7303 7.39L16.1303 4.62C16.0303 4.44 15.8203 4.38 15.6403 4.44L13.6503 5.24C13.2303 4.92 12.7903 4.66 12.3003 4.46L12.0003 2.34C11.9703 2.14 11.8003 2 11.6003 2H8.40025C8.20025 2 8.04025 2.14 8.01025 2.34L7.71025 4.46C7.22025 4.66 6.77025 4.93 6.36025 5.24L4.37025 4.44C4.19025 4.37 3.98025 4.44 3.88025 4.62L2.28025 7.39C2.18025 7.57 2.22025 7.78 2.38025 7.9L4.07025 9.22C4.03025 9.47 4.00025 9.74 4.00025 10C4.00025 10.26 4.02025 10.53 4.06025 10.78L2.37025 12.1C2.22025 12.22 2.18025 12.44 2.27025 12.61L3.87025 15.38C3.97025 15.56 4.18025 15.62 4.36025 15.56L6.35025 14.76C6.77025 15.08 7.21025 15.34 7.70025 15.54L8.00025 17.66C8.04025 17.86 8.20025 18 8.40025 18H11.6003C11.8003 18 11.9703 17.86 11.9903 17.66L12.2903 15.54C12.7803 15.34 13.2303 15.07 13.6403 14.76L15.6303 15.56C15.8103 15.63 16.0203 15.56 16.1203 15.38L17.7203 12.61C17.8203 12.43 17.7803 12.22 17.6203 12.1L15.9503 10.78ZM10.0003 13C8.35025 13 7.00025 11.65 7.00025 10C7.00025 8.35 8.35025 7 10.0003 7C11.6503 7 13.0003 8.35 13.0003 10C13.0003 11.65 11.6503 13 10.0003 13Z" />
                    </svg>
                    Configurações
                </StyledNavLink>
            </NavbarLinks>
            <Disconnect onClick={disconnect}>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M8.40833 12.9917L9.58333 14.1667L13.75 10L9.58333 5.83333L8.40833 7.00833L10.5583 9.16667H2.5V10.8333H10.5583L8.40833 12.9917ZM15.8333 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V7.5H4.16667V4.16667H15.8333V15.8333H4.16667V12.5H2.5V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5Z" />
                </svg>
                Desconectar
            </Disconnect>
        </UserNavActionsContainer>
    )
}

export default UserNavActions
