import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const UserNavActionsContainer = styled.div`
    width: 250px;
    height: 445px;
    margin-top: 8px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: linear-gradient(
        90.02deg,
        #f7f8fa 0.01%,
        #f2f4f8 99.99%,
        #f1f4f9 99.99%
    );
    box-shadow: -5px 4px 25px -2px rgba(0, 0, 0, 0.08);
    border-radius: 5px 0px 5px 5px;
`

export const NavbarLinks = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledNavLink = styled(NavLink).attrs({
    activeClassName: 'nav-item-active',
})`
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: #000000;

    width: 250px;
    height: 44px;

    display: flex;
    align-items: center;

    padding-left: 20px;

    svg {
        fill: black;
        box-sizing: content-box;
        padding-right: 10px;
    }

    :hover {
        background: linear-gradient(
            90deg,
            rgba(196, 196, 196, 0.25) 0%,
            rgba(196, 196, 196, 0.15) 100%
        );
    }

    &.nav-item-active {
        padding-left: 17px;
        border-left: 3px solid #2979ff;
        font-weight: 600;
        background: linear-gradient(
            90deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0.75) 100%
        );

        svg {
            fill: #2979ff;
        }
    }
`

export const Disconnect = styled.div`
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: #000000;

    width: 250px;
    height: 44px;
    border-radius: 0px 0px 5px 5px;

    display: flex;
    align-items: center;

    padding-left: 20px;
    cursor: pointer;

    svg {
        fill: black;
        box-sizing: content-box;
        padding-right: 10px;
    }

    :hover {
        background: linear-gradient(
            90deg,
            rgba(196, 196, 196, 0.25) 0%,
            rgba(196, 196, 196, 0.15) 100%
        );
    }
`
