import styled from 'styled-components'

export const RewardsContainer = styled.div`
    position: relative;
    margin-top: 60px;

    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(120, 120, 120, 0.5);
    border-radius: 5px;
    width: 290px;
    height: 75px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    h1 {
        position: absolute;
        left: 0;
        top: -29px;

        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;
        color: #000000;
    }
`

export const RewardContainer = styled.div`
    position: relative;
    width: 40px;
    height: 40px;

    img:hover + p {
        opacity: 1;
    }

    p {
        position: absolute;
        width: ${props => (props.width ? '180px' : '164px')};
        padding: 10px 14px;

        left: 50%;
        top: 82px;
        transform: translateX(-50%) translateY(-50%);

        border-radius: 5px;
        background: #343649;
        transition: all 0.2s ease-in;

        pointer-events: none;
        opacity: 0;
        z-index: 1;

        display: flex;
        align-items: flex-start;

        img {
            margin-right: 5px;
        }

        /* text */
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 500;
        font-size: 10px;
        line-height: 145%;
        color: #e8e9ed;

        ::after {
            content: '';
            position: absolute;
            transform: translateX(-50%) translateY(-50%);
            left: 50%;
            top: -9px;
            border-top: 10px solid transparent;
            border-bottom: 10px solid #343649;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
        }
    }
`

export const NoRewardContainer = styled.div`
    position: relative;
    width: 40px;
    height: 40px;

    img:hover + p {
        opacity: 1;
    }

    p {
        position: absolute;
        width: 138px;
        padding: 10px 14px;

        left: 50%;
        top: 82px;
        transform: translateX(-50%) translateY(-50%);

        border-radius: 5px;
        background: #343649;
        transition: all 0.2s ease-in;

        pointer-events: none;
        opacity: 0;
        z-index: 1;

        /* text */
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 500;
        font-size: 10px;
        line-height: 145%;
        color: rgba(232, 233, 237, 0.65);

        ::after {
            content: '';
            position: absolute;
            transform: translateX(-50%) translateY(-50%);
            left: 50%;
            top: -9px;
            border-top: 10px solid transparent;
            border-bottom: 10px solid #343649;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
        }
    }
`
