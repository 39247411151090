import { useRegisterAndLogin } from '../../context/RegisterAndLoginContext'

import {
    WarningToRegisterContainer,
    Title,
    Paragraph,
    Registrar,
} from './styles'

function WarningToRegister() {
    const { setIsVisible, setPage } = useRegisterAndLogin()

    return (
        <WarningToRegisterContainer>
            <Title>
                Participe da <br /> comunidade <br />
                <span>
                    m8<span>!</span>
                </span>
            </Title>
            <Paragraph>
                Descubra as melhores salas de bate papo para jogos.
            </Paragraph>
            <Registrar
                onClick={() => {
                    setIsVisible(true)
                    setPage('Cadastrar-se')
                }}
            >
                Cadastrar-se
            </Registrar>
        </WarningToRegisterContainer>
    )
}

export default WarningToRegister
