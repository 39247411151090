import { useState, useEffect } from 'react'

import {
    JogadorListContainer,
    Jogador,
    JogadorName,
    SolicitationButton,
} from './styles'

import avatar from './../../assets/avatar/avatar.jpg'
import jogadorSVG from './../../assets/svg/jogador.svg'

function JogadorList({ search }) {
    const [jogadores, setJogadores] = useState([])

    useEffect(() => {
        setJogadores([
            {
                avatar: avatar,
                name: 'Samuel',
                solicitation: false,
            },
            {
                avatar: avatar,
                name: 'Guilherme',
                solicitation: false,
            },
            {
                avatar: avatar,
                name: 'Pedro',
                solicitation: false,
            },
            {
                avatar: avatar,
                name: 'Gustavo',
                solicitation: true,
            },
            {
                avatar: avatar,
                name: 'Laís',
                solicitation: true,
            },
            {
                avatar: avatar,
                name: 'Joana',
                solicitation: true,
            },
            {
                avatar: avatar,
                name: 'Maria',
                solicitation: false,
            },
        ])
    }, [])

    return (
        <JogadorListContainer>
            {jogadores.map((jogador, index) => {
                return (
                    <Jogador key={index}>
                        <img src={jogador.avatar} alt="Avatar" />
                        <JogadorName>{jogador.name}</JogadorName>
                        <SolicitationButton
                            className={jogador.solicitation ? 'disabled' : ''}
                        >
                            <img src={jogadorSVG} alt="Jogador" />
                        </SolicitationButton>
                    </Jogador>
                )
            })}
        </JogadorListContainer>
    )
}

export default JogadorList
