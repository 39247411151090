function FormatterViewersNumbers(viewers) {
    let text = ' mil espectadores'
    let formattedNumber = (viewers / 1_000).toFixed(1).replace('.', ',')

    if ((viewers / 1_000).toFixed(1) % 1 === 0) {
        formattedNumber = (viewers / 1_000).toFixed(0).replace('.', ',')
    }

    if (viewers < 1_000) {
        text = ' espectadores'
        formattedNumber = viewers
    }

    if (viewers >= 1_000_000) {
        formattedNumber = (viewers / 1_000_000).toFixed(3).replace('.', ',')
    }

    return formattedNumber + text
}

export default FormatterViewersNumbers
