import { useState } from 'react'

import {
    Exit,
    Shadow,
    AlertModalContainer,
    Paragraph,
    SecondParagraph,
    Buttons,
    Reset,
    Back,
} from './styles'

function SalaExit({ expand, permission }) {
    const [isVisible, setIsVisible] = useState(false)

    function Modal() {
        if (permission === 1) {
            return (
                <>
                    <Shadow onClick={() => setIsVisible(false)} />
                    <AlertModalContainer permission={permission}>
                        <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => setIsVisible(false)}
                        >
                            <path
                                d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z"
                                fill="black"
                            />
                        </svg>
                        <Paragraph>
                            Tem certeza que deseja sair da sala atual?
                        </Paragraph>
                        <SecondParagraph>
                            Aleatóriamente outra pessoa será escolhida para ser
                            Administrador(a) da sala.
                        </SecondParagraph>
                        <Buttons>
                            <Reset>Sair</Reset>
                            <Back onClick={() => setIsVisible(false)}>
                                Voltar
                            </Back>
                        </Buttons>
                    </AlertModalContainer>
                </>
            )
        }

        return (
            <>
                <Shadow onClick={() => setIsVisible(false)} />
                <AlertModalContainer>
                    <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => setIsVisible(false)}
                    >
                        <path
                            d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z"
                            fill="black"
                        />
                    </svg>
                    <Paragraph>
                        Tem certeza que deseja sair da sala atual?
                    </Paragraph>
                    <Buttons>
                        <Reset>Sair</Reset>
                        <Back onClick={() => setIsVisible(false)}>Voltar</Back>
                    </Buttons>
                </AlertModalContainer>
            </>
        )
    }

    return (
        <>
            <Exit
                className={expand ? 'active' : ''}
                onClick={() => setIsVisible(true)}
                permission={permission}
            >
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M8.40833 12.9917L9.58333 14.1667L13.75 10L9.58333 5.83333L8.40833 7.00833L10.5583 9.16667H2.5V10.8333H10.5583L8.40833 12.9917ZM15.8333 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V7.5H4.16667V4.16667H15.8333V15.8333H4.16667V12.5H2.5V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5Z" />
                </svg>
            </Exit>
            {isVisible && <Modal />}
        </>
    )
}

export default SalaExit
