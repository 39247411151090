import styled from 'styled-components'

export const InicioContainer = styled.div``

export const Main = styled.main`
    display: flex;
    justify-content: space-between;
    margin: 100px 5%;
`

export const UserStats = styled.div`
    display: flex;
    flex-direction: column;
`
