import {
    SalaUsersContainer,
    UsersContainer,
    Topic,
    User,
    AvatarWrapper,
    UserAvatar,
    Status,
    UserName,
} from './styles'

function SalaUsers({ users, display }) {
    const online = users.filter(user => user.status !== 'offline')
    const offline = users.filter(user => user.status === 'offline')

    return (
        <SalaUsersContainer display={display ? 'none' : 'block'}>
            {online.length ? (
                <UsersContainer>
                    <Topic>Lista de Jogadores - {online.length}</Topic>
                    {online.map((user, index) => {
                        return (
                            <User key={index}>
                                <AvatarWrapper>
                                    <UserAvatar
                                        src={user.avatar}
                                        alt="Avatar"
                                    />
                                    <Status status={user.status} />
                                </AvatarWrapper>
                                <UserName>{user.name}</UserName>
                            </User>
                        )
                    })}
                </UsersContainer>
            ) : (
                ''
            )}
            {offline.length ? (
                <UsersContainer>
                    <Topic>Offline - {offline.length}</Topic>
                    {offline.map((user, index) => {
                        return (
                            <User key={index} offline>
                                <AvatarWrapper>
                                    <UserAvatar
                                        src={user.avatar}
                                        alt="Avatar"
                                    />
                                </AvatarWrapper>
                                <UserName>{user.name}</UserName>
                            </User>
                        )
                    })}
                </UsersContainer>
            ) : (
                ''
            )}
        </SalaUsersContainer>
    )
}

export default SalaUsers
