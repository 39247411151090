import { useState } from 'react'

import {
    UserAvatarContainer,
    Wrapper,
    Avatar,
    Image,
    Edit,
    Action,
    Name,
} from './styles'

import {
    Shadow,
    AlertModalContainer,
    Paragraph,
    Buttons,
    Reset,
    Back,
} from './styles'

import avatar from './../../assets/avatar/avatar.jpg'
import edit from './../../assets/svg/edit.svg'

function UserAvatar() {
    const [isVisible, setIsVisible] = useState(false)

    return (
        <>
            <UserAvatarContainer>
                <Wrapper>
                    <Avatar>
                        <Image src={avatar} alt="Imagem de avatar" />
                        <Edit src={edit} alt="Mudar imagem" />
                        <div>
                            <Action>Mudar avatar</Action>
                            <Action onClick={() => setIsVisible(true)}>
                                Remover avatar
                            </Action>
                        </div>
                    </Avatar>
                    <Name>DarkGameslvl</Name>
                </Wrapper>
            </UserAvatarContainer>
            {isVisible && (
                <>
                    <Shadow onClick={() => setIsVisible(false)} />
                    <AlertModalContainer>
                        <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => setIsVisible(false)}
                        >
                            <path
                                d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z"
                                fill="black"
                            />
                        </svg>
                        <Paragraph>
                            Tem certeza que deseja resetar seu avatar atual?
                        </Paragraph>
                        <Buttons>
                            <Reset>Resetar</Reset>
                            <Back onClick={() => setIsVisible(false)}>
                                Voltar
                            </Back>
                        </Buttons>
                    </AlertModalContainer>
                </>
            )}
        </>
    )
}

export default UserAvatar
