import { useEffect } from 'react'

const useNextClick = (referrer, callback = '') => {
    const handleClick = e => {
        const ref = referrer.current

        if (callback) return callback()

        if (!ref) return

        ref.contains(e.target)
            ? ref.classList.toggle('active')
            : ref.classList.remove('active')
    }

    useEffect(() => {
        document.addEventListener('click', handleClick)

        return () => document.removeEventListener('click', handleClick)
    })
}

export default useNextClick
