import styled from 'styled-components'

export const Shadow = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 5;

    background: rgba(0, 0, 0, 0.5);
`

export const RegisterAndLoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    position: fixed;
    left: 50vw;
    top: 50vh;
    transform: translate(-50%, -50%);

    z-index: 10;

    width: 450px;
    background: #ffffff;
    border-radius: 9px;
`

export const Title = styled.div`
    margin-top: 40px;

    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    text-align: center;

    span {
        font-weight: bold;
        font-size: 36px;
        line-height: 100%;
    }
`

export const Actions = styled.div`
    width: 380px;
    padding-bottom: 5px;
    margin: 38px 0 25px 0;
    border-bottom: 1px solid #a5a5a5;
`

export const Entrar = styled.span`
    padding: 0 3px;
    padding-bottom: 4px;
    font-weight: 500;
    font-size: 14px;
    margin-right: 9px;
    cursor: pointer;

    &.active {
        color: #2979ff;
        border-bottom: 2px solid #2979ff;
    }
`

export const Cadastrar = styled.span`
    padding: 0 3px;
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 4px;
    cursor: pointer;

    &.active {
        color: #2979ff;
        border-bottom: 2px solid #2979ff;
    }
`

export const Form = styled.label`
    display: flex;
    align-items: center;

    width: 380px;
    min-height: 40px;
    background: #e3e3e3;
    border-radius: 20px;
    margin-bottom: 15px;

    &.focus {
        background: #ffffff;
        border: 2px solid #2979ff;

        svg {
            fill: #353535;
            border-right: 1px solid #353535;
        }
    }

    &.ativado svg {
        fill: #353535;
        border-right: 1px solid #353535;
    }
`

export const Svg = styled.svg`
    box-sizing: content-box;

    fill: #a6a6a6;

    margin-left: 20px;
    padding: 1.5px 15px 1.5px 0;
    border-right: 1px solid #a6a6a6;
`

export const Input = styled.input`
    width: 80%;

    margin-left: 10px;

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;

    ::placeholder {
        color: #a6a6a6;
    }
`

export const Options = styled.div`
    display: flex;
    width: 352px;
    justify-content: space-between;

    margin-bottom: 35px;
`

export const StayConnected = styled.div`
    display: flex;
    cursor: pointer;

    svg {
        margin-right: 5px;
    }

    svg.hidden {
        display: none;
    }
`

export const Text = styled.span`
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 12px;
    user-select: none;
`

export const ForgotPassword = styled.div`
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 12px;

    text-decoration: underline;
    user-select: none;
    cursor: pointer;
`

export const LoginButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 175px;
    height: 40px;

    margin-bottom: 40px;

    background: #2979ff;
    border-radius: 20px;

    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;

    user-select: none;
    cursor: pointer;

    :hover {
        opacity: 0.8;
    }
`

export const TermsAndPrivacy = styled.p`
    margin: 5px 0 22px 0;
    width: 380px;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 12px;
    text-align: center;

    span {
        cursor: pointer;
        text-decoration: underline;
        color: #2979ff;
    }
`

export const RegisterButton = styled(LoginButton)`
    width: 220px;
`

// Email verification Styles

export const EmailVerificationContainer = styled(RegisterAndLoginContainer)``

export const EmailTitle = styled.div`
    align-self: flex-start;
    margin: 40px 0 0 35px;

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 24px;

    color: #000000;
`

export const EmailContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 25px 35px 0 50px;
`

export const EmailText = styled.div`
    margin-bottom: 10px;

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 18px;

    color: #000000;
`

export const Paragraph = styled.div`
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    color: #000000;

    span {
        font-weight: 500;
    }
`

export const NumberContainer = styled.div`
    display: flex;
    margin-top: 30px;
`

export const Number = styled.input`
    width: 50px;
    height: 40px;

    margin-right: 10px;

    text-align: center;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 24px;
    line-height: 100%;

    color: #000000;

    background: #cacaca;
    border-radius: 5px;

    :valid {
        background: #ffffff;
        border: 1px solid #000000;
    }
`

export const SendCode = styled.div`
    margin-top: 15px;

    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    color: #2979ff;
    cursor: pointer;
`

export const Description = styled.p`
    margin-top: 20px;

    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    color: #000000;
`

export const EmailButton = styled.button`
    margin: 50px 0 40px 0;
    align-self: flex-end;

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 16px;

    color: #000000;

    width: 120px;
    height: 40px;
    border: 1px solid #000000;
    border-radius: 5px;

    cursor: pointer;
    transition: all 0.2s ease-in;

    :hover {
        background-color: black;
        color: white;
    }
`

// Success Styles

export const SuccessContainer = styled(RegisterAndLoginContainer)`
    svg {
        margin-top: 40px;
    }
`

export const SuccessTitle = styled.div`
    margin-top: 40px;

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 24px;

    color: #2e2e2e;
`

export const SuccessSubTitle = styled.div`
    margin-top: 15px;

    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;

    color: #545454;
`

export const SuccessButton = styled.button`
    margin: 30px 0 40px 0;

    width: 80px;
    height: 45px;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 18px;

    color: #ffffff;

    background: #00e676;
    border-radius: 5px;

    user-select: none;
    cursor: pointer;

    :hover {
        opacity: 0.75;
    }
`
