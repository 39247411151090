import { useState } from 'react'

import {
    SalaChatContainer,
    ChatWrapper,
    Chat,
    InputWrapper,
    Input,
    InputPlaceholder,
    InputText,
    InputButton,
    Message,
    Avatar,
    Content,
    Infos,
    Name,
    Time,
    Text,
} from './styles'

import send from './../../assets/svg/send.svg'

function SalaChat({ messages }) {
    const [message, setMessage] = useState('')

    return (
        <SalaChatContainer>
            <ChatWrapper>
                <Chat>
                    {messages.map((info, index) => {
                        return (
                            <Message key={index}>
                                <Avatar src={info.avatar} alt="Avatar" />
                                <Content>
                                    <Infos>
                                        <Name>{info.name}</Name>
                                        <Time>Hoje às {info.time}</Time>
                                    </Infos>
                                    {info.message.map((message, index) => {
                                        return (
                                            <Text key={index}>{message}</Text>
                                        )
                                    })}
                                </Content>
                            </Message>
                        )
                    })}
                </Chat>
            </ChatWrapper>
            <InputWrapper>
                <Input>
                    <InputPlaceholder
                        className={message !== '' ? 'hidden' : ''}
                    >
                        Digite uma mensagem
                    </InputPlaceholder>
                    <InputText
                        contentEditable
                        onInput={e => setMessage(e.target.textContent)}
                    />
                </Input>
                <InputButton>
                    <img src={send} alt="Enviar mensagem" />
                </InputButton>
            </InputWrapper>
        </SalaChatContainer>
    )
}

export default SalaChat
