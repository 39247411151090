import styled from 'styled-components'

export const RecommendationsContainer = styled.div`
    position: relative;
    height: 300px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 120%);
    overflow: hidden;

    img {
        user-select: none;
        width: 100vw;
        height: 100%;
        z-index: -1;
    }
`

export const Title = styled.h1`
    position: absolute;
    width: 40%;

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 50px;
    line-height: 100%;
    text-align: center;
    color: white;
    margin-bottom: 130px;
`

export const SubTitle = styled.h2`
    position: absolute;
    width: 30%;

    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 20px;
    color: white;
    text-align: center;
    margin-bottom: 96px;
`

export const Arrows = styled.div`
    position: absolute;

    width: 100%;
    height: 100%;
    padding: 0 2.5%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    user-select: none;
    pointer-events: none;

    > svg {
        fill: white;
        cursor: pointer;

        :hover {
            fill: #b2b2b2;
        }

        &[disabled] {
            opacity: 0.2;

            :hover {
                fill: white;
            }
        }
        pointer-events: auto;
    }
`

export const ArrowLeft = styled.svg``

export const ArrowRight = styled.svg`
    transform: rotate(180deg);
`

export const Navigation = styled.div`
    position: absolute;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 22px;
    padding-bottom: 18px;

    user-select: none;
    pointer-events: none;

    > div {
        pointer-events: auto;
    }
`

export const Circle = styled.div`
    width: 8px;
    height: 8px;

    background: #ffffff;
    border-radius: 50%;

    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: pointer;

    &.active {
        border: 1.8px solid #2979ff;
    }
`

export const Recommendation = styled.div`
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    cursor: pointer;
`

export const Slider = styled.div`
    height: 100%;

    display: flex;
    transition: 1.5s ease-in-out;

    margin-left: 0%;
`
