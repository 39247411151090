import styled from 'styled-components'

export const CriarSalaContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5% 17.5%;
`

export const Back = styled.img`
    padding: 8.5px;
    background: #ffffff;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3 ease-in;

    :hover {
        background: #e8e8e8;
    }
`

export const Title = styled.div`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 24px;
    color: #000000;

    margin: 20px 0 40px 0;
`

export const Section = styled.div`
    margin-bottom: 30px;
`

export const TitleSection = styled.div`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #000000;
`

export const Input = styled.input`
    width: ${props => (props.description ? '350px' : '235px')};
    padding: 15px 35px 10px 0;
    border-bottom: 2px solid #cdcdcd;

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    color: #000000;

    ::placeholder {
        font-weight: 500;
        color: #9e9e9e;
    }

    :focus {
        border-bottom: 2px solid #2979ff;
    }
`

export const Admin = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;

    svg {
        margin-left: 20px;
    }

    svg.hidden {
        display: none;
    }
`

export const Text = styled.div`
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 13px;
    color: #5b5b5b;
`

export const Button = styled.button`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;

    width: 105px;
    height: 41px;

    background: #bababa;
    border-radius: 5px;

    &.allowed {
        cursor: pointer;
        background: #2979ff;

        :hover {
            filter: brightness(0.9);
        }
    }
`

export const PrivacityOption = styled.div`
    display: inline-block;
    width: 171px;
    height: 90px;
    background: #ffffff;
    border: 1px solid #bdbdbd;
    border-radius: 10px;

    padding: 15px 20px;
    margin-top: 18px;

    position: relative;
    cursor: pointer;

    + div {
        margin-left: 15px;
    }

    &.active {
        background: #fbfdff;
        border: 1px solid #2979ff;
    }

    > svg {
        position: absolute;
        right: 20px;
        top: 15px;
    }

    > svg.hidden {
        display: none;
    }
`

export const PrivacityTitle = styled.div`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    color: #272727;
`

export const PrivacityDescription = styled.div`
    width: 131px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 12px;
    color: #5b5b5b;

    margin-top: 10px;
`

export const ImagemContainer = styled.div`
    width: 357px;
    height: 90px;
    background: #ffffff;
    border: 1px dashed #bdbdbd;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 25px;
    margin-top: 15px;

    cursor: pointer;
`

export const ImageTextWrapper = styled.div``

export const ImageTitle = styled.div`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    color: #272727;
    margin-bottom: 10px;
`

export const ImageSubTitle = styled.div`
    width: 205px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 12px;
    color: #5b5b5b;
`

export const ImageUploaded = styled.button`
    width: 50px;
    height: 50px;
    border-radius: 50%;

    background: #f0f0f0;
    cursor: pointer;
`

export const Select = styled.div`
    position: relative;
`

export const Mask = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;
    padding: 0 15px;
    margin-top: 15px;

    background: #ffffff;
    border: 1px solid #bdbdbd;

    width: 357px;
    height: 52px;
    cursor: pointer;
    user-select: none;

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    color: #c4c4c4;

    ::after {
        content: '';
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #302b63;
        transition: all 0.2s ease-out;
        transform: ${props => (props.open ? 'scaleY(-1)' : '')};
    }

    &.active {
        color: #000000;
    }
`

export const Search = styled.label`
    display: flex;
    align-items: center;

    position: absolute;
    top: 51px;
    z-index: 10;

    width: 357px;
    height: 49px;
    background: #ffffff;
    border: 1px solid #bdbdbd;

    visibility: ${props => (props.open ? 'visible' : 'hidden')};

    &.focus {
        background: #ffffff;
        border: 2px solid #2979ff;

        svg {
            fill: #353535;
        }
    }
`

export const Svg = styled.svg`
    fill: #dddddd;
    margin: 0 10px 0 20px;

    &.ativado {
        fill: #353535;
    }
`

export const SearchInput = styled.input`
    width: 80%;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: #000000;

    ::placeholder {
        color: #c4c4c4;
    }
`

export const Options = styled.ul`
    position: absolute;
    top: 99px;

    overflow-y: scroll;
    max-height: 246px;

    width: 357px;
    border: 1px solid #bdbdbd;

    z-index: 5;
    visibility: ${props => (props.open ? 'visible' : 'hidden')};
`

export const Option = styled.li`
    height: 49px;
    padding: 16px 15px;

    background-color: #fafafa;
    cursor: pointer;

    /* text */
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: #353535;
    user-select: none;

    &.active {
        font-weight: 600;
        background: #dfdfdf;

        :hover {
            background: #dfdfdf;
        }
    }

    :hover {
        background: #f2f2f2;
    }
`

export const Container = styled.div``

export const NextSvg = styled.svg`
    fill: #bababa;

    &.active {
        fill: #2979ff;
    }
`

export const RoomSimulator = styled.div`
    width: 286px;
    height: 468px;

    padding: 20px 25px;

    background: #ffffff;
    border: 1px solid #dfdfdf;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
`

export const SimulatorGameName = styled.div`
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    text-align: center;

    margin-bottom: 13px;
`

export const SimulatorImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 236px;
    height: 176px;

    margin-bottom: 25px;

    background: linear-gradient(90deg, #e4e4e4 0%, #ebebeb 50%, #e4e4e4 100%);
    border-radius: 15px;
`

export const SimulatorRoomName = styled.div`
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 16px;
    color: #000000;

    width: fit-content;
    max-width: 236px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    padding-bottom: 10px;
    border-bottom: 1px solid #c2c2c2;

    margin-bottom: 10px;
`

export const SimulatorRoomDescription = styled.div`
    width: 236px;
    max-height: 90px;

    text-overflow: ellipsis;
    overflow: hidden;

    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 13px;
    line-height: 140%;
    text-align: justify;
    color: #000000;
`

export const CreatedBy = styled.div`
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    color: #bebebe;
    text-align: center;
    margin-top: 25px;
`

export const Time = styled.div`
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 13px;
    color: #bebebe;
    text-align: center;
    margin-top: 4px;
`
