import { UserConfigContainer } from './styles'

function UserConfig() {
    return (
        <UserConfigContainer>
            Nenhuma opção de configuração disponível no momento.
        </UserConfigContainer>
    )
}

export default UserConfig
