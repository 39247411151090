import { useEffect, useState } from 'react'
import { useAuthenticated } from '../../context/AuthenticatedContext'
import { api } from '../../services/api'

import FormatterTemplateUrl from '../../utils/FormatterTemplateUrl'
import FormatterViewersNumbers from '../../utils/FormatterViewersNumbers'

import user from './../../assets/svg/user.svg'

import {
    GamesContainer,
    Game,
    Contador,
    Star,
    Svg,
    Value,
    Imagem,
    Nome,
    Espectadores,
    Shadow,
} from './styles'

function Games() {
    const { isLogged } = useAuthenticated()
    const [games, setGames] = useState([])

    async function FetchMyApi() {
        const { data } = await api.get('', {
            params: {
                limit: 15,
                offset: 0,
            },
            headers: {
                'Client-ID': 'obb7t6xec0avvgd7592drc2neaxeik',
                Accept: 'application/vnd.twitchtv.v5+json',
            },
        })

        const result = data.top.map((item, index) => {
            return {
                id: index,
                viewers: FormatterViewersNumbers(item.viewers),
                img: FormatterTemplateUrl(item.game.box.template),
                name: item.game.localized_name,
            }
        })

        setGames(result)
    }

    useEffect(() => {
        FetchMyApi()
        const intervalId = setInterval(() => {
            FetchMyApi()
        }, 1000 * 60)

        return () => clearInterval(intervalId)
    }, [])

    return (
        <GamesContainer>
            {games.map(game => {
                return (
                    <Game key={game.id}>
                        <Contador>
                            <Svg src={user} alt="Ícone de usuário" />
                            <Value>0</Value>
                        </Contador>
                        {isLogged ? <FavStar /> : ''}
                        <Shadow />
                        <Imagem
                            src={game.img}
                            alt={`Imagem de destaque de ${game.name}`}
                        />
                        <Nome>{game.name}</Nome>
                        <Espectadores>{game.viewers}</Espectadores>
                    </Game>
                )
            })}
        </GamesContainer>
    )
}

function FavStar() {
    const [fav, setFav] = useState(false)

    return (
        <Star
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setFav(!fav)}
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M19.4957 7.85983L19.4958 7.86001C19.5059 7.89095 19.4975 7.92505 19.4741 7.94787L19.474 7.9479L14.9873 12.3211L14.7991 12.5046L14.8435 12.7637L15.9027 18.9387C15.9083 18.9709 15.895 19.0035 15.8685 19.0228C15.8421 19.042 15.807 19.0446 15.778 19.0293L10.2327 16.1138L10 15.9915L9.76732 16.1138L4.22176 19.0294L4.22167 19.0294C4.20914 19.036 4.19563 19.0392 4.18185 19.0392C4.16384 19.0392 4.14627 19.0337 4.13136 19.0229C4.10477 19.0036 4.0916 18.971 4.09711 18.9389L4.09712 18.9388L5.15603 12.7637L5.20045 12.5046L5.01223 12.3211L0.526 7.94796L0.525957 7.94791C0.502543 7.9251 0.494115 7.89097 0.504225 7.85987L0.504242 7.85981C0.514342 7.82872 0.541244 7.80608 0.5735 7.80139L0.573577 7.80138L6.77355 6.90041L7.03367 6.86261L7.15001 6.6269L9.92297 1.00868L9.47461 0.787385L9.92297 1.00868C9.93744 0.979352 9.96729 0.960785 10 0.960785C10.0328 0.960785 10.0626 0.979374 10.077 1.00861L10.0771 1.00867L12.8499 6.6269L12.9662 6.86261L13.2264 6.90041L19.4265 7.80138L19.4265 7.8014C19.4587 7.80607 19.4856 7.82863 19.4957 7.85983Z"
                    fill={fav ? '#FFDF40' : '#F0F0F0'}
                    stroke={fav ? '#DCBD1D' : '#BABABA'}
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </Star>
    )
}

export default Games
