import styled from 'styled-components'

export const SalaChatContainer = styled.div`
    position: relative;

    flex: 1;
    background: #ffffff;

    border-right: 1px solid #e2e2e2;
    border-left: 1px solid #e2e2e2;

    overflow: hidden;

    display: flex;
    flex-direction: column;

    z-index: 5;
`

export const ChatWrapper = styled.div`
    flex: 1;

    overflow-y: auto;

    padding: 25px 20px 25px 20px;
    margin-right: 5px;

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #dfdfdf;
        border-radius: 15px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #979797;
        border-radius: 15px;
    }

    ::-webkit-scrollbar-button {
        height: 3px;
    }
`

export const Chat = styled.div`
    overflow: hidden;
`

export const InputWrapper = styled.div`
    position: relative;
    margin: 0 20px 25px 20px;
`

export const Input = styled.div`
    position: relative;

    background: #f0f0f0;
    border: 1px solid #dadada;
    box-shadow: 0px 0px 20px -2px rgba(0, 0, 0, 0.15);
    border-radius: 23px;
`

export const InputPlaceholder = styled.div`
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 12px;
    color: #1a1a1a;

    position: absolute;
    pointer-events: none;

    padding: 15.5px 50px 15.5px 25px;

    &.hidden {
        visibility: hidden;
    }
`

export const InputText = styled.div`
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 12px;
    color: #000000;

    max-height: 121px;
    overflow-y: scroll;

    margin-right: 45px;

    padding: 14.5px 10px 14.5px 25px;

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #d5d5d5;
        border: 0.225em solid #f0f0f0;
        border-radius: 15px;

        :hover {
            background-color: #c7c7c7;
        }

        :active {
            background-color: #b3b3b3;
        }
    }

    ::-webkit-scrollbar-button {
        height: 10px;
    }
`

export const InputButton = styled.button`
    line-height: 0;
    z-index: 5;

    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #ffffff;

    position: absolute;
    right: 5px;
    bottom: 5px;

    cursor: pointer;

    > img {
        margin: 0 0 2px 2px;
    }

    :hover {
        filter: brightness(0.88);
    }
`

export const Message = styled.div`
    display: flex;

    + div {
        margin-top: 15px;
    }
`

export const Avatar = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
`

export const Infos = styled.div`
    display: flex;
    align-items: flex-end;

    margin: 3px 0 5px 0;
`

export const Name = styled.div`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 12px;
    color: #000000;
`

export const Time = styled.div`
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 10px;
    color: #3f3f3f;

    margin-left: 5px;
`

export const Text = styled.div`
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #000000;

    + div {
        margin-top: 5px;
    }
`
