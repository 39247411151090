import { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuthenticated } from './../../context/AuthenticatedContext'
import { useRegisterAndLogin } from '../../context/RegisterAndLoginContext'
import useNextClick from '../../hooks/useNextClick'

import {
    NavbarContainer,
    NavbarWrapper,
    Logo,
    NavbarLinks,
    StyledNavLink,
    Entrar,
    Registrar,
} from './styles'

import {
    Avatar,
    UserDropdown,
    UserButton,
    Nickname,
    ArrowDown,
    UserMenu,
    UserMenuItem,
    StatusDropDown,
    StatusButton,
    StatusText,
    StatusMenu,
    StatusLabel,
    StatusMenuItem,
    Circle,
    GreenCircle,
    RedCircle,
} from './styles'

import avatar from './../../assets/avatar/avatar.jpg'
import arrowDown from '../../assets/svg/arrowDown.svg'
import person from '../../assets/svg/person.svg'
import settings from '../../assets/svg/settings.svg'
import exit from '../../assets/svg/exit.svg'

function Navbar() {
    const { isLogged } = useAuthenticated()
    const { setIsVisible, setPage } = useRegisterAndLogin()

    return (
        <NavbarContainer>
            <NavbarWrapper>
                <Logo to="/">m8</Logo>

                <NavbarLinks>
                    <StyledNavLink to="/" exact>
                        Início
                    </StyledNavLink>
                    <StyledNavLink to="/buscar/sala">Buscar Sala</StyledNavLink>
                    <StyledNavLink to="/buscar/jogadores" exact>
                        Buscar Jogadores
                    </StyledNavLink>
                </NavbarLinks>

                {isLogged ? (
                    <UserLogged />
                ) : (
                    <>
                        <Entrar
                            onClick={() => {
                                setIsVisible(true)
                                setPage('Login')
                            }}
                        >
                            Entrar
                        </Entrar>
                        <Registrar
                            onClick={() => {
                                setIsVisible(true)
                                setPage('Cadastrar-se')
                            }}
                        >
                            Cadastrar-se
                        </Registrar>
                    </>
                )}
            </NavbarWrapper>
        </NavbarContainer>
    )
}

function UserLogged() {
    const [status, setStatus] = useState('online')
    const { setIsLogged } = useAuthenticated()
    const history = useHistory()
    const userDropDownRef = useRef(null)
    const statusDropDownRef = useRef(null)

    useNextClick(userDropDownRef)
    useNextClick(statusDropDownRef)

    function disconnect() {
        setIsLogged(false)
        history.push('/')
    }

    return (
        <>
            <UserDropdown>
                <UserButton ref={userDropDownRef}>
                    <Avatar src={avatar} alt="Avatar" />
                    <Nickname>DarkGameslvl</Nickname>
                    <ArrowDown src={arrowDown} alt="Seta para baixo" />
                </UserButton>
                <UserMenu>
                    <UserMenuItem onClick={() => history.push('/user/perfil')}>
                        <img src={person} alt="Perfil" />
                        Perfil
                    </UserMenuItem>
                    <UserMenuItem onClick={() => history.push('/user/config')}>
                        <img src={settings} alt="Configurações" />
                        Configurações
                    </UserMenuItem>
                    <UserMenuItem onClick={disconnect}>
                        <img src={exit} alt="Desconectar" />
                        Desconectar
                    </UserMenuItem>
                </UserMenu>
            </UserDropdown>

            <StatusDropDown>
                <StatusButton ref={statusDropDownRef}>
                    <StatusText status={status}>{status}</StatusText>
                    <Circle status={status} />
                </StatusButton>
                <StatusMenu>
                    <StatusLabel>Status</StatusLabel>
                    <StatusMenuItem onClick={() => setStatus('online')}>
                        <GreenCircle />
                        Disponível
                    </StatusMenuItem>
                    <StatusMenuItem onClick={() => setStatus('offline')}>
                        <RedCircle />
                        Não perturbar
                    </StatusMenuItem>
                </StatusMenu>
            </StatusDropDown>
        </>
    )
}

export default Navbar
