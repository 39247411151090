import styled from 'styled-components'

export const JogadorListContainer = styled.div`
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(4, 250px);
    column-gap: 15px;
    row-gap: 15px;
`

export const Jogador = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 250px;
    height: 60px;

    background: #ffffff;
    border-radius: 5px;

    > img {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        margin-left: 15px;
    }
`

export const JogadorName = styled.div`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 12px;
    color: #000000;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    margin-left: 10px;
    flex: 1;
`

export const SolicitationButton = styled.button`
    width: 30px;
    height: 30px;

    background: #ff9100;
    border-radius: 5px;

    cursor: pointer;
    margin-right: 10px;

    :hover {
        filter: brightness(0.9);
    }

    &.disabled {
        opacity: 0.25;

        cursor: default;

        :hover {
            filter: brightness(1);
        }
    }
`
