import { useAuthenticated } from '../../context/AuthenticatedContext'

import Recommendations from '../../components/Recommendations'
import WarningToRegister from '../../components/WarningToRegister'
import Games from '../../components/Games'

import { InicioContainer, Main, UserStats } from './styles'
import FriendList from '../../components/FriendList'
import Rewards from '../../components/Rewards'

function Inicio() {
    const { isLogged } = useAuthenticated()

    return (
        <InicioContainer>
            <Recommendations />
            <Main>
                {isLogged ? (
                    <UserStats>
                        <FriendList />
                        <Rewards />
                    </UserStats>
                ) : (
                    <WarningToRegister />
                )}
                <Games />
            </Main>
        </InicioContainer>
    )
}

export default Inicio
