import { useState } from 'react'
import { Switch, Route } from 'react-router-dom'

import Salas from '../../components/Salas'
import SalasSearch from '../../components/SalasSearch'

import MinhasSalas from '../../components/MinhasSalas'
import SalasNavActions from '../../components/SalasNavActions'

import { BuscarSalasContainer, Wrapper } from './styles'

function BuscarSalas() {
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)

    return (
        <BuscarSalasContainer>
            <Wrapper>
                <SalasSearch
                    search={search}
                    setSearch={setSearch}
                    page={page}
                />
                <SalasNavActions page={page} />
            </Wrapper>
            <Switch>
                <Route exact path="/buscar/sala/">
                    <Salas search={search} setPage={setPage} />
                </Route>
                <Route exact path="/buscar/sala/minhas-salas/">
                    <MinhasSalas search={search} setPage={setPage} />
                </Route>
            </Switch>
        </BuscarSalasContainer>
    )
}

export default BuscarSalas
