import styled from 'styled-components'

export const UserContainer = styled.div`
    display: flex;

    padding: 50px 5%;
`

export const Wrapper = styled.div``

export const Content = styled.div`
    height: 650px;
    width: 100%;
    padding: 45px 60px;
    background: #ffffff;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
`
