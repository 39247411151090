import { useState } from 'react'

import { RewardsContainer, RewardContainer, NoRewardContainer } from './styles'

import prize1 from './../../assets/svg/prize1.svg'
import prize2 from './../../assets/svg/prize2.svg'
import iconOff from './../../assets/svg/iconOff.svg'
import iconOn from './../../assets/svg/iconOn.svg'
import noPrize from './../../assets/svg/noPrize.svg'

function Rewards() {
    const [reward] = useState(false)

    return (
        <RewardsContainer>
            <h1>Suas recompensas</h1>
            <Reward reward={reward} />
            <NoReward />
            <NoReward />
            <NoReward />
            <NoReward />
        </RewardsContainer>
    )
}

function Reward({ reward }) {
    return (
        <>
            {reward ? (
                <RewardContainer width>
                    <img src={prize2} alt="Recompensa desbloqueada" />
                    <p>
                        <img src={iconOn} alt="Icon" />
                        Fez sua primeira amizade na plataforma!
                    </p>
                </RewardContainer>
            ) : (
                <RewardContainer>
                    <img src={prize1} alt="Recompensa bloqueada" />
                    <p>
                        <img src={iconOff} alt="Icon" />
                        Faça uma amizade na plataforma!
                    </p>
                </RewardContainer>
            )}
        </>
    )
}

function NoReward() {
    return (
        <NoRewardContainer>
            <img src={noPrize} alt="Sem recompensa" />
            <p>Novas recompensas disponíveis em breve!</p>
        </NoRewardContainer>
    )
}

export default Rewards
