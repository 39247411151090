import { useState, useRef, useEffect } from 'react'
import { useAuthenticated } from '../../context/AuthenticatedContext'
import { useRegisterAndLogin } from '../../context/RegisterAndLoginContext'

import {
    Shadow,
    RegisterAndLoginContainer,
    Title,
    Actions,
    Entrar,
    Cadastrar,
    Form,
    Svg,
    Input,
    Options,
    StayConnected,
    Text,
    ForgotPassword,
    LoginButton,
    TermsAndPrivacy,
    RegisterButton,
} from './styles'

import {
    EmailVerificationContainer,
    EmailTitle,
    EmailContainer,
    EmailText,
    Paragraph,
    SendCode,
    Description,
    EmailButton,
    NumberContainer,
    Number,
} from './styles'

import {
    SuccessContainer,
    SuccessTitle,
    SuccessSubTitle,
    SuccessButton,
} from './styles'

function RegisterAndLogin({ page, setPage }) {
    const { setIsVisible } = useRegisterAndLogin()

    return (
        <>
            <Shadow onClick={() => setIsVisible(false)} />
            {page === 'Login' ? (
                <Login page={page} setPage={setPage} />
            ) : (
                <Register page={page} setPage={setPage} />
            )}
        </>
    )
}

function Login({ setPage }) {
    const { setIsVisible } = useRegisterAndLogin()
    const { setIsLogged } = useAuthenticated()
    const userRef = useRef(null)
    const passwordRef = useRef(null)
    const [values, setValues] = useState({ user: '', password: '' })
    const [stayConnected, setStayConnected] = useState(false)

    function onChange(e, ref) {
        const { value, id } = e.target
        value !== ''
            ? ref.current.classList.add('ativado')
            : ref.current.classList.remove('ativado')

        setValues({ ...values, [id]: value })
    }

    function onFocus(ref) {
        ref.current.classList.toggle('focus')
    }

    return (
        <RegisterAndLoginContainer>
            <Title>
                Entrar na <br />
                <span>m8!</span>
            </Title>
            <Actions>
                <Entrar className="active">Entrar</Entrar>
                <Cadastrar onClick={() => setPage('Cadastrar-se')}>
                    Cadastrar-se
                </Cadastrar>
            </Actions>
            <Form ref={userRef}>
                <Svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M7.5 0C5.31917 0 3.54492 1.77425 3.54492 3.95508C3.54492 6.13591 5.31917 7.91016 7.5 7.91016C9.68083 7.91016 11.4551 6.13591 11.4551 3.95508C11.4551 1.77425 9.68083 0 7.5 0Z" />
                    <path d="M12.4209 10.494C11.3381 9.39454 9.90261 8.78906 8.37891 8.78906H6.62109C5.09742 8.78906 3.66193 9.39454 2.57912 10.494C1.50161 11.5881 0.908203 13.0322 0.908203 14.5605C0.908203 14.8032 1.10496 15 1.34766 15H13.6523C13.895 15 14.0918 14.8032 14.0918 14.5605C14.0918 13.0322 13.4984 11.5881 12.4209 10.494Z" />
                </Svg>
                <Input
                    id="user"
                    type="text"
                    value={values.user}
                    onChange={e => onChange(e, userRef)}
                    placeholder="Usuário"
                    onFocus={() => onFocus(userRef)}
                    onBlur={() => onFocus(userRef)}
                />
            </Form>
            <Form ref={passwordRef}>
                <Svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M2.92665 15H12.0705C12.7256 15 13.2583 14.4673 13.2583 13.8122V7.25816C13.2583 6.60306 12.7256 6.07041 12.0705 6.07041H12.0399V4.50306C12.0399 2.02041 10.0195 0 7.53685 0C5.0542 0 3.03379 2.02041 3.03379 4.50306V6.07041H2.92971C2.27461 6.07041 1.74196 6.60306 1.74196 7.25816V13.8122C1.7389 14.4673 2.27155 15 2.92665 15ZM5.00216 4.50306C5.00216 3.10714 6.13788 1.96837 7.53685 1.96837C8.93277 1.96837 10.0715 3.10408 10.0715 4.50306V6.07041H5.00216V4.50306Z" />
                </Svg>
                <Input
                    id="password"
                    type="password"
                    value={values.password}
                    onChange={e => onChange(e, passwordRef)}
                    placeholder="Senha"
                    onFocus={() => onFocus(passwordRef)}
                    onBlur={() => onFocus(passwordRef)}
                />
            </Form>
            <Options>
                <StayConnected onClick={() => setStayConnected(!stayConnected)}>
                    <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={stayConnected ? '' : 'hidden'}
                    >
                        <path
                            d="M13.3333 0H1.66667C0.741667 0 0 0.75 0 1.66667V13.3333C0 14.25 0.741667 15 1.66667 15H13.3333C14.2583 15 15 14.25 15 13.3333V1.66667C15 0.75 14.2583 0 13.3333 0ZM5.83333 11.6667L1.66667 7.5L2.84167 6.325L5.83333 9.30833L12.1583 2.98333L13.3333 4.16667L5.83333 11.6667Z"
                            fill="#2979FF"
                        />
                    </svg>
                    <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={!stayConnected ? '' : 'hidden'}
                    >
                        <path
                            d="M13.3333 1.66667V13.3333H1.66667V1.66667H13.3333ZM13.3333 0H1.66667C0.75 0 0 0.75 0 1.66667V13.3333C0 14.25 0.75 15 1.66667 15H13.3333C14.25 15 15 14.25 15 13.3333V1.66667C15 0.75 14.25 0 13.3333 0Z"
                            fill="#A6A6A6"
                        />
                    </svg>
                    <Text>Permanecer conectado</Text>
                </StayConnected>
                <ForgotPassword>Esqueci minha senha</ForgotPassword>
            </Options>
            <LoginButton
                onClick={() => {
                    setIsLogged(true)
                    setIsVisible(false)
                }}
            >
                Entrar
            </LoginButton>
        </RegisterAndLoginContainer>
    )
}

function Register({ setPage }) {
    const userRef = useRef(null)
    const passwordRef = useRef(null)
    const rePasswordRef = useRef(null)
    const emailRef = useRef(null)
    const [nextStep, setNextStep] = useState(false)
    const [values, setValues] = useState({
        user: '',
        password: '',
        rePassword: '',
        email: '',
    })

    function onChange(e, ref) {
        const { value, id } = e.target
        value !== ''
            ? ref.current.classList.add('ativado')
            : ref.current.classList.remove('ativado')

        setValues({ ...values, [id]: value })
    }

    function onFocus(ref) {
        ref.current.classList.toggle('focus')
    }

    if (nextStep) {
        return <EmailVerification email={values.email} />
    }

    return (
        <RegisterAndLoginContainer>
            <Title>
                Junte-se <br />
                hoje à <span>m8!</span>
            </Title>
            <Actions>
                <Entrar onClick={() => setPage('Login')}>Entrar</Entrar>
                <Cadastrar className="active">Cadastrar-se</Cadastrar>
            </Actions>
            <Form ref={userRef}>
                <Svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M7.5 0C5.31917 0 3.54492 1.77425 3.54492 3.95508C3.54492 6.13591 5.31917 7.91016 7.5 7.91016C9.68083 7.91016 11.4551 6.13591 11.4551 3.95508C11.4551 1.77425 9.68083 0 7.5 0Z" />
                    <path d="M12.4209 10.494C11.3381 9.39454 9.90261 8.78906 8.37891 8.78906H6.62109C5.09742 8.78906 3.66193 9.39454 2.57912 10.494C1.50161 11.5881 0.908203 13.0322 0.908203 14.5605C0.908203 14.8032 1.10496 15 1.34766 15H13.6523C13.895 15 14.0918 14.8032 14.0918 14.5605C14.0918 13.0322 13.4984 11.5881 12.4209 10.494Z" />
                </Svg>
                <Input
                    id="user"
                    type="text"
                    value={values.user}
                    onChange={e => onChange(e, userRef)}
                    placeholder="Usuário"
                    onFocus={() => onFocus(userRef)}
                    onBlur={() => onFocus(userRef)}
                />
            </Form>
            <Form ref={passwordRef}>
                <Svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M2.92665 15H12.0705C12.7256 15 13.2583 14.4673 13.2583 13.8122V7.25816C13.2583 6.60306 12.7256 6.07041 12.0705 6.07041H12.0399V4.50306C12.0399 2.02041 10.0195 0 7.53685 0C5.0542 0 3.03379 2.02041 3.03379 4.50306V6.07041H2.92971C2.27461 6.07041 1.74196 6.60306 1.74196 7.25816V13.8122C1.7389 14.4673 2.27155 15 2.92665 15ZM5.00216 4.50306C5.00216 3.10714 6.13788 1.96837 7.53685 1.96837C8.93277 1.96837 10.0715 3.10408 10.0715 4.50306V6.07041H5.00216V4.50306Z" />
                </Svg>
                <Input
                    id="password"
                    type="password"
                    value={values.password}
                    onChange={e => onChange(e, passwordRef)}
                    placeholder="Senha"
                    onFocus={() => onFocus(passwordRef)}
                    onBlur={() => onFocus(passwordRef)}
                />
            </Form>
            <Form ref={rePasswordRef}>
                <Svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M2.92665 15H12.0705C12.7256 15 13.2583 14.4673 13.2583 13.8122V7.25816C13.2583 6.60306 12.7256 6.07041 12.0705 6.07041H12.0399V4.50306C12.0399 2.02041 10.0195 0 7.53685 0C5.0542 0 3.03379 2.02041 3.03379 4.50306V6.07041H2.92971C2.27461 6.07041 1.74196 6.60306 1.74196 7.25816V13.8122C1.7389 14.4673 2.27155 15 2.92665 15ZM5.00216 4.50306C5.00216 3.10714 6.13788 1.96837 7.53685 1.96837C8.93277 1.96837 10.0715 3.10408 10.0715 4.50306V6.07041H5.00216V4.50306Z" />
                </Svg>
                <Input
                    id="rePassword"
                    type="password"
                    value={values.rePassword}
                    onChange={e => onChange(e, rePasswordRef)}
                    placeholder="Confirmação de senha"
                    onFocus={() => onFocus(rePasswordRef)}
                    onBlur={() => onFocus(rePasswordRef)}
                />
            </Form>
            <Form ref={emailRef}>
                <Svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0)">
                        <path d="M9.94336 7.57699L15.0002 10.7739V4.24512L9.94336 7.57699Z" />
                        <path d="M0.000244141 4.24512V10.7739L5.05712 7.57699L0.000244141 4.24512Z" />
                        <path d="M14.0627 2.34375H0.937695C0.469883 2.34375 0.0986328 2.6925 0.0283203 3.14156L7.5002 8.06437L14.9721 3.14156C14.9018 2.6925 14.5305 2.34375 14.0627 2.34375Z" />
                        <path d="M9.08465 8.14327L7.75809 9.01702C7.67934 9.06858 7.59027 9.0939 7.50027 9.0939C7.41027 9.0939 7.32121 9.06858 7.24246 9.01702L5.9159 8.14233L0.0302734 11.8651C0.102461 12.3105 0.471836 12.6564 0.937773 12.6564H14.0628C14.5287 12.6564 14.8981 12.3105 14.9703 11.8651L9.08465 8.14327Z" />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect
                                width="15"
                                height="15"
                                fill="white"
                                transform="translate(0.000244141)"
                            />
                        </clipPath>
                    </defs>
                </Svg>
                <Input
                    id="email"
                    type="email"
                    value={values.email}
                    onChange={e => onChange(e, emailRef)}
                    placeholder="E-mail"
                    onFocus={() => onFocus(emailRef)}
                    onBlur={() => onFocus(emailRef)}
                />
            </Form>
            <TermsAndPrivacy>
                Ao clicar em Cadastrar-se, você confirma que leu e reconhece os{' '}
                <span>Termos de Serviço</span> e o{' '}
                <span>Aviso de privacidade</span>.
            </TermsAndPrivacy>
            <RegisterButton onClick={() => setNextStep(true)}>
                Cadastrar-se
            </RegisterButton>
        </RegisterAndLoginContainer>
    )
}

function EmailVerification({ email }) {
    const [nextStep, setNextStep] = useState(false)
    const [codeNumbers, setCodeNumbers] = useState({
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
    })

    function onChange(e, id) {
        const value = e.target.value

        if (!isNaN(value)) setCodeNumbers({ ...codeNumbers, [id]: value })
    }

    if (nextStep) {
        return <Success />
    }

    return (
        <EmailVerificationContainer>
            <EmailTitle>Verifique seu e-mail</EmailTitle>
            <EmailContainer>
                <EmailText>Insira seu código de verificação</EmailText>
                <Paragraph>
                    Enviamos um código de 6 dígitos para <span>{email}</span>.
                    Confirme que ele pertence a você para manter a sua conta
                    segura.
                </Paragraph>
                <NumberContainer>
                    <Number
                        type="text"
                        maxLength="1"
                        value={codeNumbers['1']}
                        onChange={e => onChange(e, 1)}
                        required
                    />
                    <Number
                        type="text"
                        maxLength="1"
                        value={codeNumbers['2']}
                        onChange={e => onChange(e, 2)}
                        required
                    />
                    <Number
                        type="text"
                        maxLength="1"
                        value={codeNumbers['3']}
                        onChange={e => onChange(e, 3)}
                        required
                    />
                    <Number
                        type="text"
                        maxLength="1"
                        value={codeNumbers['4']}
                        onChange={e => onChange(e, 4)}
                        required
                    />
                    <Number
                        type="text"
                        maxLength="1"
                        value={codeNumbers['5']}
                        onChange={e => onChange(e, 5)}
                        required
                    />
                    <Number
                        type="text"
                        maxLength="1"
                        value={codeNumbers['6']}
                        onChange={e => onChange(e, 6)}
                        required
                    />
                </NumberContainer>
                <SendCode>Reenviar código</SendCode>
                <Description>
                    Você também pode clicar no link que enviamos para você por
                    e-mail.
                </Description>
                <EmailButton onClick={() => setNextStep(true)}>
                    Enviar
                </EmailButton>
            </EmailContainer>
        </EmailVerificationContainer>
    )
}

function Success() {
    const { setIsLogged } = useAuthenticated()
    const { setIsVisible } = useRegisterAndLogin()

    useEffect(() => {
        setIsLogged(true)
    })

    return (
        <SuccessContainer>
            <svg
                width="100"
                height="100"
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    cx="50"
                    cy="50"
                    r="48"
                    stroke="#E9F6E1"
                    strokeWidth="4"
                />
                <path
                    d="M41.1255 63.5308L28.0942 50.4995L23.6567 54.9058L41.1255 72.3745L78.6255 34.8745L74.2192 30.4683L41.1255 63.5308Z"
                    fill="#A5DC86"
                />
            </svg>
            <SuccessTitle>Sucesso</SuccessTitle>
            <SuccessSubTitle>Sua conta foi criada!</SuccessSubTitle>
            <SuccessButton onClick={() => setIsVisible(false)}>
                OK
            </SuccessButton>
        </SuccessContainer>
    )
}

export default RegisterAndLogin
