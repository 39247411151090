import styled from 'styled-components'

export const Exit = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    position: absolute;
    right: -32px;
    top: ${props => (props.permission === 1 ? '48px' : '10px')};

    width: 50px;
    height: 32px;

    background: #ffffff;
    border: 1px solid #e31d1d;
    border-radius: 0px 20px 20px 0px;

    opacity: 0.6;
    z-index: 3;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    &.active {
        top: ${props => (props.permission === 1 ? '72px' : '34px')};
    }

    > svg {
        transition: all 0.25s ease-in-out;
        fill: #e31d1d;
        margin-right: 6px;
    }

    :hover {
        opacity: 1;
        right: -45px;
    }
`

export const Shadow = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 5;

    background: rgba(0, 0, 0, 0.35);
`

export const AlertModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    position: fixed;
    left: 50vw;
    top: 50vh;
    transform: translate(-50%, -50%);

    width: 331px;
    height: ${props => (props.permission === 1 ? '213px' : '169px')};

    z-index: 10;

    background: #ffffff;
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.15);
    border-radius: 9px;

    svg {
        margin: 10px 10px 0 0;
        padding: 5px;
        align-self: flex-end;
        box-sizing: content-box;

        border-radius: 3px;

        cursor: pointer;

        :hover {
            background: #d9d9d9;
        }
    }
`

export const Paragraph = styled.div`
    padding: 5px 37px 0 37px;

    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #2e2e2e;
`

export const SecondParagraph = styled.div`
    padding: 10px 37px 0 37px;

    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 12px;
    line-height: 140%;
    color: #484848;
`

export const Buttons = styled.div`
    display: flex;
    width: 100%;
    padding: 20px 37px 0 37px;
    justify-content: space-between;
`

export const Reset = styled.button`
    width: 93px;
    height: 41px;

    background: #fe0303;
    border-radius: 5px;

    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;

    cursor: pointer;

    :hover {
        filter: brightness(0.875);
    }
`

export const Back = styled.button`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    color: #2e2e2e;

    cursor: pointer;

    border-radius: 3px;
    padding: 0 18px;

    :hover {
        background: #d9d9d9cc;
    }
`
