import { useState, useLayoutEffect } from 'react'

import {
    NoRoomContainer,
    Title,
    SubTitle,
    CardContainer,
    Card,
    CardName,
    CardDescription,
    CardWrapper,
    CardGame,
    CardButton,
} from './styles'

import search from './../../assets/svg/search.svg'
import exclamation from './../../assets/svg/exclamation.svg'
import persons from './../../assets/svg/persons.svg'

function Salas({ search, setPage }) {
    const [salas, setSalas] = useState([])

    useLayoutEffect(() => {
        setPage(1)

        setSalas([
            {
                image:
                    'https://cdn.vox-cdn.com/thumbor/HyOhm280EOQO2ubcOZCSONkDGb8=/0x0:1200x675/1200x800/filters:focal(504x242:696x434)/cdn.vox-cdn.com/uploads/chorus_image/image/68567666/Dr._STONE_Season_2_release_date_Episode_24_ending_with_Stone_Wars_Dr._STONE_manga_compared_to_the_anime_Spoilers.0.jpg',
                name: 'Domadores de Tanh Kench',
                description:
                    'It is a long established fact that a reader will be distracted by the readable content of a page w... ver mais',
                game: 'League of legends',
            },
        ])
    }, [setPage])

    if (search !== '' && salas.length === 0) {
        return <NoResults />
    }

    return salas.length === 0 ? (
        <NoRoom />
    ) : (
        <CardContainer>
            {salas.map((sala, index) => {
                return (
                    <Card key={index}>
                        <img src={sala.image} alt="Imagem da sala" />
                        <CardName>{sala.name}</CardName>
                        <CardDescription>{sala.description}</CardDescription>
                        <CardWrapper>
                            <CardGame>
                                <img src={persons} alt="Jogadores" />
                                {sala.game}
                            </CardGame>
                            <CardButton>Entrar</CardButton>
                        </CardWrapper>
                    </Card>
                )
            })}
        </CardContainer>
    )
}

function NoRoom() {
    return (
        <NoRoomContainer>
            <img src={search} alt="Sem resultado" />
            <Title>Opps! Parece que ainda não existe nenhuma sala</Title>
            <SubTitle>
                Clique no botão em azul para criar uma nova sala
                <img src={exclamation} alt="Exclamação" />
            </SubTitle>
        </NoRoomContainer>
    )
}

function NoResults() {
    return (
        <NoRoomContainer>
            <Title>Nenhum resultado foi encontrado</Title>
            <SubTitle>Use palavras-chave diferentes</SubTitle>
        </NoRoomContainer>
    )
}

export default Salas
