import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const SalasNavActionsContainer = styled.div`
    display: flex;
    gap: 10px;
`

export const StyledLink = styled(Link)`
    display: inline-flex;
    align-items: center;

    padding: 0 15px;

    height: 33px;

    background: ${props => (props.blue ? '#2979ff' : '#FFD817;')};
    box-shadow: 0px 0px 4px ${props => (props.blue ? '#2979ff' : '#FFD817;')};
    border-radius: 5px;

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 11px;
    line-height: 100%;
    color: #ffffff;

    > svg {
        margin-right: 10px;
    }
`
