import styled from 'styled-components'

export const UserAvatarContainer = styled.div`
    width: 250px;
    height: 197px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: linear-gradient(180deg, #f8f9fb 0%, #ffffff 100%);
    box-shadow: -5px 4px 25px -2px rgba(0, 0, 0, 0.08);
    border-radius: 5px 5px 0px 5px;
`
export const Wrapper = styled.div``

export const Avatar = styled.div`
    display: flex;
    justify-content: center;
    position: relative;

    > div {
        position: absolute;
        width: 127px;
        height: 66px;

        padding: 6px 0;

        left: 115%;
        top: 127px;
        transform: translateX(-50%) translateY(-50%);

        border: 1px solid #c5c5c5;
        border-radius: 5px;
        background: #343649;
        transition: all 0.25s ease-in;

        opacity: 0;
        visibility: hidden;
        z-index: 1;

        display: flex;
        flex-direction: column;

        :hover {
            opacity: 1;
            visibility: visible;
        }

        ::after {
            content: '';
            position: absolute;
            transform: translateX(-50%) translateY(-50%);
            left: 10%;
            top: -7px;
            border-top: 8px solid transparent;
            border-bottom: 8px solid #343649;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
        }

        ::before {
            content: '';
            position: absolute;
            transform: translateX(-50%) translateY(-50%);
            left: 10%;
            top: -8.75px;
            border-top: 8px solid transparent;
            border-bottom: 8px solid #c5c5c5;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
        }
    }
`

export const Image = styled.img`
    box-sizing: content-box;
    width: 75px;
    height: 75px;
    border: 1px solid #000000;
    border-radius: 50%;
`

export const Edit = styled.img`
    position: absolute;
    right: calc(((100% - 77px) / 2) - 2px);
    bottom: -4px;

    cursor: pointer;

    :hover + div {
        opacity: 1;
        visibility: visible;
    }
`

export const Action = styled.div`
    width: 125px;
    height: 27px;

    display: flex;
    align-items: center;

    padding-left: 15px;

    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;

    cursor: pointer;

    :hover {
        background: #2979ff;
    }
`

export const Name = styled.div`
    margin-top: 15px;

    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px;
    color: #000000;
`

// Alert Modal Styles

export const Shadow = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 5;

    background: rgba(0, 0, 0, 0.35);
`

export const AlertModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    position: fixed;
    left: 50vw;
    top: 50vh;
    transform: translate(-50%, -50%);

    width: 331px;
    height: 169px;

    z-index: 10;

    background: #ffffff;
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.15);
    border-radius: 9px;

    svg {
        margin: 10px 10px 0 0;
        padding: 5px;
        align-self: flex-end;
        box-sizing: content-box;

        border-radius: 3px;

        cursor: pointer;

        :hover {
            background: #d9d9d9;
        }
    }
`

export const Paragraph = styled.div`
    padding: 5px 37px 0 37px;

    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #2e2e2e;
`

export const Buttons = styled.div`
    display: flex;
    width: 100%;
    padding: 20px 37px 0 37px;
    justify-content: space-between;
`

export const Reset = styled.button`
    width: 93px;
    height: 41px;

    background: #fe0303;
    border-radius: 5px;

    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;

    cursor: pointer;

    :hover {
        filter: brightness(0.875);
    }
`

export const Back = styled.button`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    color: #2e2e2e;

    cursor: pointer;

    border-radius: 3px;
    padding: 0 18px;

    :hover {
        background: #d9d9d9cc;
    }
`
