import styled from 'styled-components'

export const SalaInfoContainer = styled.div`
    display: inline-block;

    width: 236px;
    height: 602px;

    margin: 30px 35px 0 45px;
`

export const GameName = styled.div`
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    text-align: center;
    margin-bottom: 13px;
`

export const RoomImage = styled.div`
    width: 236px;
    height: 176px;

    background: linear-gradient(90deg, #e4e4e4 0%, #ebebeb 50%, #e4e4e4 100%);
    border-radius: 15px;
    overflow: hidden;

    margin-bottom: 25px;

    display: flex;
    align-items: center;
    justify-content: center;

    > img {
        width: 100%;
        height: 100%;
    }
`

export const RoomName = styled.div`
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 16px;
    color: #000000;

    width: fit-content;
    max-width: 236px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    padding-bottom: 10px;
    border-bottom: 1px solid #c2c2c2;

    margin-bottom: 10px;
`

export const RoomDescription = styled.div`
    width: 236px;
    max-height: 90px;

    text-overflow: ellipsis;
    overflow: hidden;

    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 13px;
    line-height: 140%;
    text-align: justify;
    color: #000000;

    margin-bottom: 200px;
`

export const CreatedBy = styled.div`
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    color: #bebebe;
    text-align: center;
    margin-top: 25px;
`

export const HasCreated = styled.div`
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 13px;
    color: #bebebe;
    text-align: center;
    margin-top: 4px;
`

export const NoImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const NoImageButton = styled.button`
    line-height: 0;

    width: 50px;
    height: 50px;

    border-radius: 50%;
    background: #d5d5d5;

    cursor: pointer;

    margin-bottom: 8px;

    transition: all 0.2s;

    :hover {
        filter: brightness(0.95);
    }
`

export const NoImageTitle = styled.div`
    width: ${props => (props.small ? '114px' : '151px')};

    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 10px;
    color: #454545;
    text-align: center;
`

export const NoImageLink = styled.div`
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 10px;
    text-decoration: underline;
    color: #454545;

    cursor: pointer;

    margin-top: 10px;
`
