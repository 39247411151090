import { useState, useLayoutEffect } from 'react'

import {
    NoRoomContainer,
    Title,
    SubTitle,
    CardContainer,
    Card,
    CardName,
    CardDescription,
    CardGame,
    RoomTitle,
} from './styles'

import alone from './../../assets/svg/alone.svg'
import exclamation from './../../assets/svg/exclamation.svg'
import persons from './../../assets/svg/persons.svg'

function MinhasSalas({ search, setPage }) {
    const [salas, setSalas] = useState([])

    useLayoutEffect(() => {
        setPage(2)

        setSalas([
            {
                image:
                    'https://cdn.vox-cdn.com/thumbor/HyOhm280EOQO2ubcOZCSONkDGb8=/0x0:1200x675/1200x800/filters:focal(504x242:696x434)/cdn.vox-cdn.com/uploads/chorus_image/image/68567666/Dr._STONE_Season_2_release_date_Episode_24_ending_with_Stone_Wars_Dr._STONE_manga_compared_to_the_anime_Spoilers.0.jpg',
                name: 'Domadores de Tanh Kench',
                description:
                    'It is a long established fact that a reader will be distracted by the readable content of a page w... ver mais',
                game: 'League of legends',
            },
        ])
    }, [setPage])

    if (search !== '' && salas.length === 0) {
        return (
            <>
                <RoomTitle>Minhas salas</RoomTitle>
                <NoResults />
            </>
        )
    }

    return (
        <>
            <RoomTitle>Minhas salas</RoomTitle>
            {salas.length === 0 ? (
                <NoRoom />
            ) : (
                <CardContainer>
                    {salas.map((sala, index) => {
                        return (
                            <Card key={index} to="/sala/6582745187904">
                                <img src={sala.image} alt="Imagem da sala" />
                                <CardName>{sala.name}</CardName>
                                <CardDescription>
                                    {sala.description}
                                </CardDescription>
                                <CardGame>
                                    <img src={persons} alt="Jogadores" />
                                    {sala.game}
                                </CardGame>
                            </Card>
                        )
                    })}
                </CardContainer>
            )}
        </>
    )
}

function NoRoom() {
    return (
        <NoRoomContainer>
            <img src={alone} alt="Sem resultado" />
            <Title>Você não está participando de nenhuma sala no momento</Title>
            <SubTitle>
                Pesquise uma sala na aba principal ou crie a sua própria
                <img src={exclamation} alt="Exclamação" />
            </SubTitle>
        </NoRoomContainer>
    )
}

function NoResults() {
    return (
        <NoRoomContainer margin>
            <Title>Nenhuma de suas salas contém este nome</Title>
            <SubTitle>Use palavras-chave diferentes</SubTitle>
        </NoRoomContainer>
    )
}

export default MinhasSalas
