import { useRef } from 'react'
import useSlider from '../../hooks/useSlider'

import lol from './../../assets/images/leagueoflegends.png'
import vava from './../../assets/images/valorant.png'
import cs from './../../assets/images/counterstrike.png'

import {
    RecommendationsContainer,
    Title,
    SubTitle,
    ArrowLeft,
    ArrowRight,
    Navigation,
    Circle,
    Arrows,
    Recommendation,
    Slider,
} from './styles'

function Recommendations() {
    const sliderRef = useRef(null)
    const [page, setPage] = useSlider(sliderRef, 3)

    function HandleClickPage(e) {
        console.log('Você clicou em:', e.target.className)
    }

    return (
        <RecommendationsContainer>
            <Arrows>
                <ArrowLeft
                    disabled={page === 1}
                    onClick={() => page !== 1 && setPage(page - 1)}
                    width="22"
                    height="40"
                    viewBox="0 0 22 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M22 35.3L8.40216 20L22 4.7L17.8138 0L0 20L17.8138 40L22 35.3Z" />
                </ArrowLeft>
                <ArrowRight
                    disabled={page === 3}
                    onClick={() => page !== 3 && setPage(page + 1)}
                    width="22"
                    height="40"
                    viewBox="0 0 22 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M22 35.3L8.40216 20L22 4.7L17.8138 0L0 20L17.8138 40L22 35.3Z" />
                </ArrowRight>
            </Arrows>
            <Navigation>
                <Circle
                    className={page === 1 ? 'active' : ''}
                    onClick={() => setPage(1)}
                />
                <Circle
                    className={page === 2 ? 'active' : ''}
                    onClick={() => setPage(2)}
                />
                <Circle
                    className={page === 3 ? 'active' : ''}
                    onClick={() => setPage(3)}
                />
            </Navigation>

            <Slider ref={sliderRef}>
                <Recommendation onClick={HandleClickPage}>
                    <img src={lol} alt="League of legends" />
                    <Title>
                        Salas de <br />
                        League of Legends
                    </Title>
                    <SubTitle>converse com a comunidade!</SubTitle>
                </Recommendation>
                <Recommendation onClick={HandleClickPage}>
                    <img src={vava} alt="Valorant" />
                    <Title>Salas de Valorant</Title>
                    <SubTitle>converse com a comunidade!</SubTitle>
                </Recommendation>
                <Recommendation onClick={HandleClickPage}>
                    <img src={cs} alt="Counter-strike" />
                    <Title>
                        Salas de <br />
                        Counter-Strike
                    </Title>
                    <SubTitle>converse com a comunidade!</SubTitle>
                </Recommendation>
            </Slider>
        </RecommendationsContainer>
    )
}

export default Recommendations
