import { Switch, Route } from 'react-router-dom'

import Inicio from './pages/Inicio'
import User from './pages/User'

import BuscarSalas from './pages/BuscarSalas'
import CriarSala from './components/CriarSala'

import BuscarJogadores from './pages/BuscarJogadores'
import Sala from './pages/Sala'

export default function MainRoutes() {
    return (
        <>
            <Switch>
                <Route exact path="/" component={Inicio} />
                <Route path="/user/" component={User} />
                <Route
                    exact
                    path="/buscar/sala/criar-sala/"
                    component={CriarSala}
                />
                <Route path="/buscar/sala" component={BuscarSalas} />
                <Route
                    exact
                    path="/buscar/jogadores"
                    component={BuscarJogadores}
                />
                <Route path="/sala/:id" component={Sala} />
            </Switch>
        </>
    )
}
