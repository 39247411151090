import styled from 'styled-components'

export const Settings = styled.div`
    display: ${props => (props.permission === 1 ? 'flex' : 'none')};
    align-items: center;
    justify-content: flex-end;

    position: absolute;
    right: -32px;
    top: 10px;

    width: 50px;
    height: 32px;

    background: #ffffff;
    border: 1px solid #2979ff;
    border-radius: 0px 20px 20px 0px;

    opacity: 0.6;
    z-index: 3;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    &.active {
        top: 34px;
    }

    > svg {
        transition: all 0.25s ease-in-out;
        fill: #2979ff;
        margin-right: 6px;
    }

    :hover {
        opacity: 1;
        right: -45px;
    }
`

export const Shadow = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: ${props => (props.buffed ? '10' : '5')};

    background: rgba(0, 0, 0, 0.35);
`

export const FirstModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    position: fixed;
    left: 50vw;
    top: 50vh;
    transform: translate(-50%, -50%);

    width: 600px;
    height: 450px;

    z-index: 10;

    background: #ffffff;
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
`

export const Svg = styled.svg`
    margin: 10px 10px 0 0;
    padding: 5px;
    align-self: flex-end;
    box-sizing: content-box;

    border-radius: 3px;

    cursor: pointer;

    :hover {
        background: #f0f0f0;
    }
`

export const Button = styled.button`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;

    width: 162px;
    height: 41px;

    background: #bababa;
    border-radius: 5px;

    &.allowed {
        cursor: pointer;
        background: #2979ff;

        :hover {
            filter: brightness(0.9);
        }
    }
`

export const Wrapper = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
`

export const Section = styled.div`
    margin-bottom: 20px;
`

export const BigWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    padding: 10px 0 0 50px;
`

export const Title = styled.div`
    flex: 1;

    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 18px;
    color: #2979ff;

    margin-left: 8px;

    + svg {
        margin: 5px 5px 0 0;
    }
`

export const TitleSection = styled.div`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #000000;

    margin-bottom: 15px;
`

export const Input = styled.input`
    width: ${props => (props.description ? '350px' : '235px')};
    padding: 0 35px 10px 0;
    border-bottom: 2px solid #cdcdcd;

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    color: #000000;

    ::placeholder {
        font-weight: 500;
        color: #9e9e9e;
    }

    :focus {
        border-bottom: 2px solid #2979ff;
    }
`

export const Invite = styled.div`
    display: flex;
    align-items: center;

    margin-bottom: 30px;

    > svg {
        cursor: pointer;
        margin-left: 10px;
    }
`

export const InviteLink = styled.div`
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: #2979ff;

    user-select: all;
`

export const DeleteRoom = styled.div`
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    color: #fe0303;

    margin-bottom: 25px;

    cursor: pointer;
`

export const SecondModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    position: fixed;
    left: 50vw;
    top: 50vh;
    transform: translate(-50%, -50%);

    width: 331px;
    height: 213px;

    z-index: 10;

    background: #ffffff;
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.15);
    border-radius: 9px;

    svg {
        margin: 10px 10px 0 0;
        padding: 5px;
        align-self: flex-end;
        box-sizing: content-box;

        border-radius: 3px;

        cursor: pointer;

        :hover {
            background: #d9d9d9;
        }
    }
`

export const Paragraph = styled.div`
    padding: 5px 37px 0 37px;

    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #2e2e2e;
`

export const SecondParagraph = styled.div`
    padding: 10px 37px 0 37px;

    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 12px;
    line-height: 140%;
    color: #484848;
`

export const Buttons = styled.div`
    display: flex;
    width: 100%;
    padding: 20px 37px 0 37px;
    justify-content: space-between;
`

export const Reset = styled.button`
    width: 93px;
    height: 41px;

    background: #fe0303;
    border-radius: 5px;

    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;

    cursor: pointer;

    :hover {
        filter: brightness(0.875);
    }
`

export const Back = styled.button`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    color: #2e2e2e;

    cursor: pointer;

    border-radius: 3px;
    padding: 0 18px;

    :hover {
        background: #d9d9d9cc;
    }
`
