import styled from 'styled-components'
import { NavLink, Link } from 'react-router-dom'

export const NavbarContainer = styled.nav`
    background: white;
    border-bottom: 1px solid #e2e2e2;
`

export const NavbarWrapper = styled.div`
    height: 100px;
    /* max-width: 1170px; */
    margin: 0 auto;
    padding: 0 5%;

    display: flex;
    align-items: center;

    justify-content: space-between;
`

export const Logo = styled(Link)`
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 48px;
    color: inherit;
`

export const NavbarLinks = styled.div`
    flex: 1;

    padding: 16px 0 16px 33.5px;
    margin-left: 42.5px;
    border-left: 1px solid #e2e2e2;

    display: flex;
    align-items: center;
`

export const StyledNavLink = styled(NavLink).attrs({
    activeClassName: 'nav-item-active',
})`
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: #0f0f0f;
    text-transform: uppercase;

    + a {
        margin-left: 15px;
    }

    :hover {
        text-decoration: underline;
    }

    &.nav-item-active {
        color: #2979ff;

        :hover {
            text-decoration: none;
        }
    }
`

export const Entrar = styled.button`
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;

    width: 72px;
    height: 40px;

    border: 1.5px solid #000000;
    border-radius: 5px;

    cursor: pointer;
    transition: all 0.2s ease-in;

    :hover {
        background-color: black;
        color: white;
    }
`

export const Registrar = styled.button`
    background: #2979ff;

    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: white;

    width: 130px;
    height: 40px;
    margin-left: 10px;

    border-radius: 5px;

    cursor: pointer;
    transition: opacity 0.2s;

    :hover {
        opacity: 0.8;
    }
`

// Logado

export const UserDropdown = styled.div`
    margin-right: 40px;
    position: relative;
`

export const Avatar = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
`

export const UserButton = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    &.active {
        span + img {
            transform: rotate(-180deg);
        }

        + ul {
            opacity: 1;
            visibility: visible;
        }
    }
`

export const Nickname = styled.span`
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-right: 5px;
`

export const ArrowDown = styled.img`
    transition: 0.3s ease-in-out;
`

export const UserMenu = styled.ul`
    width: 100%;
    margin-top: 10px;
    position: absolute;

    overflow: hidden;
    z-index: 10;

    transition: 0.2s ease-in-out;

    visibility: hidden;
    opacity: 0;

    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
`

export const UserMenuItem = styled.li`
    display: flex;
    align-items: center;

    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 600;
    color: #000000;
    white-space: nowrap;
    padding: 8px 0 8px 15px;

    img {
        margin-right: 10px;
    }

    :hover {
        background: #c4c4c466;
        cursor: pointer;
    }

    :last-child {
        padding-bottom: 10px;
    }
`

// Status

export const StatusDropDown = styled.div`
    position: relative;
`

export const StatusButton = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    &.active {
        + ul {
            opacity: 1;
            visibility: visible;
        }
    }
`

export const StatusText = styled.span`
    margin-right: 8px;

    font-family: 'Roboto';
    font-weight: 700;
    font-size: 14px;
    color: ${props => (props.status === 'online' ? '#00e676' : '#e31d1d')};
`

export const StatusMenu = styled.ul`
    width: 140px;
    margin-top: 20px;
    position: absolute;
    right: 0;

    overflow: hidden;
    z-index: 10;

    transition: 0.2s ease-in-out;

    visibility: hidden;
    opacity: 0;

    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
`

export const StatusLabel = styled.div`
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    padding: 8px 0 5px 15px;

    color: #000000;
`

export const StatusMenuItem = styled.li`
    display: flex;
    align-items: center;

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 12px;
    color: #000000;
    white-space: nowrap;
    padding: 8px 0 8px 15px;

    :hover {
        background: #c4c4c466;
        cursor: pointer;
    }

    :last-child {
        padding-bottom: 10px;
    }
`

export const Circle = styled.div`
    width: 8px;
    height: 8px;
    background: ${props => (props.status === 'online' ? '#00e676' : '#e31d1d')};
    border-radius: 50%;
    animation: pulse 2s infinite;
    box-shadow: 0 0 0
        ${props => (props.status === 'online' ? '#00e67766' : '#e31c1c66')};

    @keyframes pulse {
        70% {
            box-shadow: 0 0 0 8px #00000000;
        }
        100% {
            box-shadow: 0 0 0 0 #00000000;
        }
    }
`

export const GreenCircle = styled.div`
    width: 8px;
    height: 8px;
    background: #00e676;
    border-radius: 50%;
    margin-right: 7px;
`

export const RedCircle = styled.div`
    width: 8px;
    height: 8px;
    background: #e31d1d;
    border-radius: 50%;
    margin-right: 7px;
`
