import styled from 'styled-components'

export const SalasContainer = styled.div``

export const NoRoomContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 85px;
`

export const Title = styled.div`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 18px;
    color: #000000;

    margin-bottom: 25px;
`

export const SubTitle = styled.div`
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 14px;
    color: #373737;

    > img {
        margin-left: 5px;
    }
`

export const CardContainer = styled.div`
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(4, 235px);
    column-gap: 30px;
    row-gap: 30px;
`

export const Card = styled.div`
    width: 235px;
    height: 298px;
    background: #ffffff;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    overflow: hidden;

    > img {
        width: 235px;
        height: 150px;
    }
`

export const CardName = styled.div`
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 12px;
    color: #000000;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    margin: 15px 15px 10px 15px;
`

export const CardDescription = styled.div`
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 10px;
    line-height: 140%;
    text-align: justify;
    color: #000000;

    margin: 0 15px;
`

export const CardWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    margin: 20px 15px 0 15px;
`

export const CardGame = styled.div`
    display: flex;
    align-items: center;

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 10px;
    line-height: 11px;
    color: #bebebe;

    > img {
        margin-right: 5px;
    }
`

export const CardButton = styled.button`
    width: 55px;
    height: 28px;

    background: #2979ff;
    box-shadow: 0px 0px 4px #2979ff;
    border-radius: 5px;

    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 10px;
    color: #ffffff;

    cursor: pointer;

    :hover {
        opacity: 0.8;
    }
`
