import styled from 'styled-components'

export const GamesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 160px);
    column-gap: 20px;
    row-gap: 30px;
`

export const Game = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;

    width: 160px;
`

export const Contador = styled.div`
    position: absolute;
    height: 26px;

    background: #ff4444;
    border-radius: 5px;

    margin: 10px 0 0 10px;

    display: flex;
    align-items: center;
    justify-content: center;
`

export const Star = styled.svg`
    position: absolute;
    margin: 10px 10px 0 0;
    right: 0;
    height: 20px;
`

export const Svg = styled.img`
    margin: 0 5px;
`

export const Value = styled.span`
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    margin-right: 5px;

    color: #ffffff;
`

export const Shadow = styled.div`
    position: absolute;
    width: 160px;
    height: 217px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.75) 0%,
        rgba(77, 77, 77, 0) 52.6%
    );
    opacity: 0.5;
    border-radius: 5px;
    z-index: -1;
`

export const Imagem = styled.img`
    width: 160px;
    height: 217px;
    border-radius: 5px;
    z-index: -5;
`

export const Nome = styled.span`
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;

    margin: 7px 0;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

export const Espectadores = styled.span`
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 14px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`
