import { useState, createContext, useContext } from 'react'

const AuthenticatedContext = createContext({})

export const AuthenticatedContextProvider = ({ children }) => {
    const [isLogged, setIsLogged] = useState(false)

    return (
        <AuthenticatedContext.Provider value={{ isLogged, setIsLogged }}>
            {children}
        </AuthenticatedContext.Provider>
    )
}

export const useAuthenticated = () => {
    return useContext(AuthenticatedContext)
}
