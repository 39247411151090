import { useState, useEffect } from 'react'

function useSlider(referrer, maxPage, time = 5000) {
    const [page, setPage] = useState(1)

    useEffect(() => {
        const ref = referrer.current
        const formula = `${(page - 1) * -100}vw`

        ref.style.marginLeft = formula

        const interval = setInterval(() => {
            page === maxPage ? setPage(1) : setPage(page + 1)
        }, time)

        return () => clearInterval(interval)
    })

    return [page, setPage]
}

export default useSlider
