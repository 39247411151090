import styled from 'styled-components'

export const SalaUsersContainer = styled.div`
    display: ${props => props.display};

    width: 315px;
    padding: 30px 25px 0 25px;

    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #d5d5d5;
        border: 0.225em solid #f6f7fa;
        border-radius: 15px;

        :hover {
            background-color: #c7c7c7;
        }

        :active {
            background-color: #b3b3b3;
        }
    }

    ::-webkit-scrollbar-button:single-button:vertical:decrement {
        height: 5px;
    }
`

export const UsersContainer = styled.div`
    margin-bottom: 30px;

    > div + div {
        margin-top: 10px;
    }
`

export const User = styled.div`
    margin-left: 5px;
    display: flex;
    align-items: center;

    opacity: ${props => (props.offline ? '50%' : '100%')};
`

export const AvatarWrapper = styled.div`
    position: relative;
    width: 35px;
    height: 35px;
`

export const UserAvatar = styled.img`
    width: 35px;
    height: 35px;
    border-radius: 50%;
`

export const Status = styled.div`
    position: absolute;
    width: 10.5px;
    height: 10.5px;
    border-radius: 50%;
    right: 1.75px;
    bottom: 0;

    background: ${props => (props.status === 'online' ? '#00e676' : '#E31D1D')};
`

export const UserName = styled.div`
    margin-left: 10px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 12px;
    color: #1a1a1a;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

export const Topic = styled.h5`
    margin-bottom: 15px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: #000000;

    text-transform: uppercase;
`
