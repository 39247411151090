import { useState } from 'react'

import {
    Settings,
    Shadow,
    FirstModalContainer,
    Button,
    Wrapper,
    BigWrapper,
    Title,
    Section,
    Svg,
    TitleSection,
    Input,
    Invite,
    InviteLink,
    DeleteRoom,
    SecondModalContainer,
    Paragraph,
    SecondParagraph,
    Buttons,
    Reset,
    Back,
} from './styles'

function SalaSettings({ expand, permission }) {
    const [firstModal, setFirstModal] = useState(false)
    const [secondModal, setSecondModal] = useState(false)
    const [values, setValues] = useState({
        name: '',
        description: '',
    })

    return (
        <>
            <Settings
                className={expand ? 'active' : ''}
                permission={permission}
                onClick={() => setFirstModal(true)}
            >
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M15.9503 10.78C15.9803 10.53 16.0003 10.27 16.0003 10C16.0003 9.73 15.9803 9.47 15.9403 9.22L17.6303 7.9C17.7803 7.78 17.8203 7.56 17.7303 7.39L16.1303 4.62C16.0303 4.44 15.8203 4.38 15.6403 4.44L13.6503 5.24C13.2303 4.92 12.7903 4.66 12.3003 4.46L12.0003 2.34C11.9703 2.14 11.8003 2 11.6003 2H8.40025C8.20025 2 8.04025 2.14 8.01025 2.34L7.71025 4.46C7.22025 4.66 6.77025 4.93 6.36025 5.24L4.37025 4.44C4.19025 4.37 3.98025 4.44 3.88025 4.62L2.28025 7.39C2.18025 7.57 2.22025 7.78 2.38025 7.9L4.07025 9.22C4.03025 9.47 4.00025 9.74 4.00025 10C4.00025 10.26 4.02025 10.53 4.06025 10.78L2.37025 12.1C2.22025 12.22 2.18025 12.44 2.27025 12.61L3.87025 15.38C3.97025 15.56 4.18025 15.62 4.36025 15.56L6.35025 14.76C6.77025 15.08 7.21025 15.34 7.70025 15.54L8.00025 17.66C8.04025 17.86 8.20025 18 8.40025 18H11.6003C11.8003 18 11.9703 17.86 11.9903 17.66L12.2903 15.54C12.7803 15.34 13.2303 15.07 13.6403 14.76L15.6303 15.56C15.8103 15.63 16.0203 15.56 16.1203 15.38L17.7203 12.61C17.8203 12.43 17.7803 12.22 17.6203 12.1L15.9503 10.78ZM10.0003 13C8.35025 13 7.00025 11.65 7.00025 10C7.00025 8.35 8.35025 7 10.0003 7C11.6503 7 13.0003 8.35 13.0003 10C13.0003 11.65 11.6503 13 10.0003 13Z" />
                </svg>
            </Settings>
            {firstModal && (
                <>
                    <Shadow onClick={() => setFirstModal(false)} />
                    <FirstModalContainer>
                        <Wrapper>
                            <svg
                                width="30"
                                height="30"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15.9503 10.78C15.9803 10.53 16.0003 10.27 16.0003 10C16.0003 9.73 15.9803 9.47 15.9403 9.22L17.6303 7.9C17.7803 7.78 17.8203 7.56 17.7303 7.39L16.1303 4.62C16.0303 4.44 15.8203 4.38 15.6403 4.44L13.6503 5.24C13.2303 4.92 12.7903 4.66 12.3003 4.46L12.0003 2.34C11.9703 2.14 11.8003 2 11.6003 2H8.40025C8.20025 2 8.04025 2.14 8.01025 2.34L7.71025 4.46C7.22025 4.66 6.77025 4.93 6.36025 5.24L4.37025 4.44C4.19025 4.37 3.98025 4.44 3.88025 4.62L2.28025 7.39C2.18025 7.57 2.22025 7.78 2.38025 7.9L4.07025 9.22C4.03025 9.47 4.00025 9.74 4.00025 10C4.00025 10.26 4.02025 10.53 4.06025 10.78L2.37025 12.1C2.22025 12.22 2.18025 12.44 2.27025 12.61L3.87025 15.38C3.97025 15.56 4.18025 15.62 4.36025 15.56L6.35025 14.76C6.77025 15.08 7.21025 15.34 7.70025 15.54L8.00025 17.66C8.04025 17.86 8.20025 18 8.40025 18H11.6003C11.8003 18 11.9703 17.86 11.9903 17.66L12.2903 15.54C12.7803 15.34 13.2303 15.07 13.6403 14.76L15.6303 15.56C15.8103 15.63 16.0203 15.56 16.1203 15.38L17.7203 12.61C17.8203 12.43 17.7803 12.22 17.6203 12.1L15.9503 10.78ZM10.0003 13C8.35025 13 7.00025 11.65 7.00025 10C7.00025 8.35 8.35025 7 10.0003 7C11.6503 7 13.0003 8.35 13.0003 10C13.0003 11.65 11.6503 13 10.0003 13Z"
                                    fill="#2979FF"
                                />
                            </svg>
                            <Title>Configurações do Grupo</Title>
                            <Svg
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => setFirstModal(false)}
                            >
                                <path
                                    d="M15 1.51071L13.4893 0L7.5 5.98929L1.51071 0L0 1.51071L5.98929 7.5L0 13.4893L1.51071 15L7.5 9.01071L13.4893 15L15 13.4893L9.01071 7.5L15 1.51071Z"
                                    fill="#989898"
                                />
                            </Svg>
                        </Wrapper>
                        <BigWrapper>
                            <Section>
                                <TitleSection>Editar Nome da Sala</TitleSection>
                                <Input
                                    type="text"
                                    value={values.name}
                                    onChange={e =>
                                        setValues({
                                            ...values,
                                            name: e.target.value,
                                        })
                                    }
                                    placeholder="Digite aqui"
                                />
                            </Section>
                            <Section>
                                <TitleSection>
                                    Editar Descrição da sala
                                </TitleSection>
                                <Input
                                    description
                                    type="text"
                                    value={values.description}
                                    onChange={e =>
                                        setValues({
                                            ...values,
                                            description: e.target.value,
                                        })
                                    }
                                    placeholder="Digite aqui"
                                />
                            </Section>
                            <TitleSection>Link de convite</TitleSection>
                            <Invite>
                                <InviteLink>
                                    https://m8.com/sala/HJFNh37nfA872hdfAN7
                                </InviteLink>
                                <svg
                                    width="15"
                                    height="18"
                                    viewBox="0 0 15 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() =>
                                        navigator.clipboard.writeText(
                                            'https://m8.com/sala/HJFNh37nfA872hdfAN7'
                                        )
                                    }
                                >
                                    <path
                                        d="M11.0526 0H1.57895C0.710526 0 0 0.710526 0 1.57895V12.6316H1.57895V1.57895H11.0526V0ZM10.2632 3.15789L15 7.89474V15.7895C15 16.6579 14.2895 17.3684 13.4211 17.3684H4.72895C3.86053 17.3684 3.15789 16.6579 3.15789 15.7895L3.16579 4.73684C3.16579 3.86842 3.86842 3.15789 4.73684 3.15789H10.2632ZM9.47368 8.68421H13.8158L9.47368 4.3421V8.68421Z"
                                        fill="#BABABA"
                                    />
                                </svg>
                            </Invite>

                            <DeleteRoom onClick={() => setSecondModal(true)}>
                                Excluir sala
                            </DeleteRoom>

                            <Button>Salvar mudanças</Button>
                        </BigWrapper>
                    </FirstModalContainer>
                </>
            )}
            {secondModal && (
                <>
                    <Shadow onClick={() => setSecondModal(false)} buffed />
                    <SecondModalContainer>
                        <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => setSecondModal(false)}
                        >
                            <path
                                d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z"
                                fill="black"
                            />
                        </svg>
                        <Paragraph>
                            Tem certeza que deseja excluir a sala atual?
                        </Paragraph>
                        <SecondParagraph>
                            A sala será excluida permanentemente e não será
                            possível reverter esta ação.
                        </SecondParagraph>
                        <Buttons>
                            <Reset>Excluir</Reset>
                            <Back onClick={() => setSecondModal(false)}>
                                Voltar
                            </Back>
                        </Buttons>
                    </SecondModalContainer>
                </>
            )}
        </>
    )
}

export default SalaSettings
